import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import createCache from "@emotion/cache";
import { CacheProvider, Global } from "@emotion/react";
import { ColorModeProvider } from "@theme-ui/color-modes";
import { ThemeProvider } from "@theme-ui/core";
import { Providers, defaultProvider } from "../common/Providers";

const cache = createCache({ key: "selfcare", nonce: "randomsecret21062024" });
const BrandingContext = createContext();

const BrandingProvider = ({ children }) => {
  const doSwitchTheme = provider => {
    setBranding({
      ...branding,
      provider: provider,
      theme: Providers[provider].theme,
      bodyFont: Providers[provider].bodyFont,
      icons: Providers[provider].icons,
      logo: Providers[provider].logo,
      logoAlt: Providers[provider].logoAlt,
      language: Providers[provider].language,
      config: Providers[provider].config,
    });
  };

  const [branding, setBranding] = useState({
    provider: defaultProvider,
    theme: Providers[defaultProvider].theme,
    bodyFont: Providers[defaultProvider].bodyFont,
    icons: Providers[defaultProvider].icons,
    logo: Providers[defaultProvider].logo,
    logoAlt: Providers[defaultProvider].logoAlt,
    language: Providers[defaultProvider].language,
    config: Providers[defaultProvider].config,
    switchTheme: doSwitchTheme,
  });

  return (
    <BrandingContext.Provider value={branding}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={branding.theme}>
          <ColorModeProvider>
            <Global
              styles={() => ({
                body: {
                  overflowY: "visible !important",
                  fontFamily: branding.bodyFont,
                  margin: 0,
                },

                "*": {
                  fontFeatureSettings: "'lnum'",
                  WebkitFontSmoothing: "antialiased",
                  MozOsxFontSmoothing: "grayscale",
                },
                "&:media screen and (max-width: 320.02px)": {
                  fontSize: `calc(10px * ${window.devicePixelRatio})`,
                },
                "&:media screen and (min-width: 320px) and (max-width: 1919.98px)": {
                  fontSize: `calc(12px + (16 - 10) * ((100vw - 320px) / (1920 - 320)) * ${window.devicePixelRatio})`,
                },
                "&:media screen and (min-width: 1920px)": {
                  fontSize: `calc(16px * ${window.devicePixelRatio})`,
                },

                // /* iOS Safari - fix for issues with zooming form controls when font-size is < 16px */
                // "@media screen and (max-width: 40em)" :{
                //   select:active, input:active, textarea:active {
                //     font-size: 16px !important;
                //   }
                // },

                // /* Safari - hide autofill icon */
                // input::-webkit-credentials-auto-fill-button {
                //   visibility: hidden;
                //   position: absolute;
                //   right: 0;
                // },
              })}
            />
            {children}
          </ColorModeProvider>
        </ThemeProvider>
      </CacheProvider>
    </BrandingContext.Provider>
  );
};

BrandingProvider.propTypes = {
  children: PropTypes.node,
};
export { BrandingProvider, BrandingContext };
