import React, { forwardRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Text, Flex } from "@theme-ui/components";

import { MapAccountStatus } from "../SubscriberInformation/SubscriberInformation";
import DescriptionRow from "./DescriptionRow";
import ViewDetails from "../ViewDetails";
import { SelfcareIcon } from "../icons";
import { parsePackageDescription, parseInvoiceDescription } from "../../common/Utilities";

import { setAccount } from "../../redux/slices/UserSlice";
import FormatPhone from "../FormatPhone";

const SACard = forwardRef(({ account, ...props }, ref) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const packageDescription = parsePackageDescription(account.packageDescription);
  const descriptionList = parseInvoiceDescription(account.packageInvoiceDescription);
  const numDescriptionRows = 2;

  return (
    <Flex
      variant="layout.card"
      bg="contentBg"
      data-testid="sa-card"
      ref={ref}
      sx={{
        px: ["medium", "default", "default", "largeish"],
        width: ["100%", "21rem", "22rem", "24.25rem"],
      }}
      {...props}>
      <Flex
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}>
        <MapAccountStatus status={account.accountStatus} />

        <ViewDetails
          label="lbl.details_cbo"
          icon="viewDeviceDetails"
          onClick={() => {
            dispatch(setAccount(account));
            history.push("/account/" + account.accountCode + "/home");
          }}
        />
      </Flex>
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}>
        <Flex
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            my: "default",
          }}>
          <SelfcareIcon name={"phone"} />

          <Text ml="small" variant="headline">
            {account.phoneNumber !== "" ? (
              <FormatPhone number={account.phoneNumber} />
            ) : (
              account.accountCode
            )}
          </Text>
        </Flex>
        <Flex
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Text sx={{ fontWeight: "semiBold", textAlign: "center" }}>{packageDescription}</Text>
          <Flex sx={{ width: "90%", flexDirection: "column" }}>
            {descriptionList &&
              descriptionList.map((item, index) => {
                if (index < numDescriptionRows) return <DescriptionRow key={index} text={item} />;
                else if (
                  index === numDescriptionRows &&
                  descriptionList.length > numDescriptionRows
                )
                  return (
                    <DescriptionRow
                      key={index}
                      text={intl.formatMessage({ id: "lbl.more_items" })}
                      list={descriptionList}
                      numRows={numDescriptionRows}
                    />
                  );
                return null;
              })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});
SACard.displayName = "SACard";
export default SACard;
