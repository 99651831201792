import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useThemeUI } from "@theme-ui/core";

import { Text, Flex, Box } from "@theme-ui/components";

import { SelfcareIcon } from "../icons";

import { setAccount } from "../../redux/slices/UserSlice";
import { useSelect } from "downshift";
import PhoneDrop from "./PhoneDrop";
import FormatPhone from "../FormatPhone";

const MenuOption = forwardRef(({ text, isActive, isHighlighted, ...props }, ref) => {
  const { theme } = useThemeUI();
  const iconHighlightedColor = isHighlighted ? theme.colors.secondary : undefined;
  const color = isActive ? theme.colors.primary : theme.colors.disabled;

  return (
    <Flex ref={ref} sx={{ alignItems: "center" }} py="tiny" px="default" {...props}>
      <SelfcareIcon
        name="phone"
        mr="largeish"
        ml="0.7rem"
        stroke={iconHighlightedColor ?? color}
        className="phoneIcon"
      />
      <Text
        ml="1.3125rem"
        variant="cardtitle"
        sx={{ transition: "100ms ease-in", fontWeight: "regular", fontFeatureSettings: "'tnum'" }}
        color={iconHighlightedColor ?? color}>
        {text}
      </Text>
    </Flex>
  );
});

MenuOption.displayName = "MenuOption";

const PhoneDropMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { serviceAgreements, account } = useSelector(state => state.user);
  const serviceAgreementsList = serviceAgreements ?? [];
  const enableDrop = serviceAgreementsList.length > 1;

  const onSelect = event => {
    const sa = event.selectedItem;
    dispatch(setAccount(sa));
    history.push("/account/" + sa.accountCode + "/home");
  };

  const defaultHighlightedIndex = serviceAgreements?.findIndex(
    sa => sa.accountCode === account?.accountCode
  );

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: serviceAgreementsList,
    defaultHighlightedIndex,
    onSelectedItemChange: onSelect,
  });

  return (
    <Box
      display="inline-block"
      sx={{
        position: "relative",
        cursor: enableDrop ? "pointer" : "default",
      }}
      {...getToggleButtonProps()}>
      <PhoneDrop isMenuOpen={isOpen} />
      <Flex {...getMenuProps()}>
        {isOpen && enableDrop && (
          <Box
            bg="contentBg"
            mt="tiny"
            sx={{
              minHeight: "2rem",
              border: "solid 1px",
              borderColor: "border",
              width: ["16rem", "16rem", "18rem"],
              zIndex: 112,
              position: "absolute",
              right: "0%",
              boxShadow: "light",
              borderRadius: "card",
              whiteSpace: "nowrap",
              maxHeight: "19rem",
              overflow: "auto",
              overflowX: "hidden",
            }}>
            {serviceAgreements.map((sa, index) => {
              return (
                <MenuOption
                  key={index}
                  isHighlighted={highlightedIndex === index}
                  isActive={sa.accountStatus === "ACTIVE"}
                  text={
                    sa.phoneNumber !== "" ? <FormatPhone number={sa.phoneNumber} /> : sa.accountCode
                  }
                  {...getItemProps({ item: sa, index })}
                />
              );
            })}
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default PhoneDropMenu;
