import { useContext } from "react";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { BrandingContext } from "../../contexts/BrandingContext";

const FormatPhone = ({ number }) => {
  const { locale } = useContext(SelfcareIntlContext);
  const { config } = useContext(BrandingContext);

  return config.phoneNumberPattern
    ? String(number).replace(
        config.phoneNumberPattern[locale].searchPattern,
        config.phoneNumberPattern[locale].replacePattern
      )
    : number;
};
export default FormatPhone;
