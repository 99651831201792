import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

import { Flex, Heading, Box } from "@theme-ui/components";

import { isMobileView } from "../../common/Utilities";
import DeviceDetailsCard from "../../components/DeviceDetails";
import { PaymentSkeleton } from "../../components/PaymentRow";
import SubHeader from "../../components/SubHeader";
import Layout from "../../containers/Layout";
import GoBack from "../../components/GoBack";
import FormatPhone from "../../components/FormatPhone";

const DeviceDetails = () => {
  const intl = useIntl();
  const history = useHistory();

  const { account } = useSelector(state => state.user);

  const isMobile = isMobileView();

  const onClose = () => {
    history.goBack();
  };

  if (!account) {
    return (
      <Layout>
        <SubHeader />
        <Flex mt="large" sx={{ flexDirection: "column" }}>
          <Heading>
            {intl.formatMessage(
              { id: "lbl.device_details_title" },
              {
                phoneNumber: isEmpty(account?.phoneNumber) ? (
                  account?.accountCode
                ) : (
                  <FormatPhone number={account?.phoneNumber} />
                ),
                isMobile,
                br: <br />,
              }
            )}
          </Heading>
          <PaymentSkeleton />
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout>
      <SubHeader />
      <Box>
        <Heading>
          {intl.formatMessage(
            { id: "lbl.device_details_title" },
            {
              phoneNumber: isEmpty(account?.phoneNumber) ? (
                account?.accountCode
              ) : (
                <FormatPhone number={account?.phoneNumber} />
              ),
              isMobile,
              br: <br />,
            }
          )}
        </Heading>
        <Flex
          variant="layout.serviceCard"
          mt={0}
          mb="0"
          sx={{
            flexDirection: "column",
            maxWidth: "67.5rem",
          }}>
          {account && <DeviceDetailsCard />}
        </Flex>
      </Box>

      <GoBack onBack={onClose} />
    </Layout>
  );
};

export default DeviceDetails;
