import { useContext } from "react";
import { UDF_CATEGORIES } from "../common/Constants";
import { BrandingContext } from "../contexts/BrandingContext";

export const usePhoneNumber = category => {
  const { config } = useContext(BrandingContext);

  return typeof config.phoneNumber === "object"
    ? config.phoneNumber[category || UDF_CATEGORIES.RESIDENTIAL]
    : config.phoneNumber;
};
