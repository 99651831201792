import { Box } from "@theme-ui/components";
import * as React from "react";
const NotificationBell = ({ fill = "contentBg", stroke = "primary", ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="-15 -10 55 55"
      preserveAspectRatio="xMidYMid meet"
      sx={{
        width: "2.3rem",
        height: "2.3rem",
      }}
      fill={fill}
      stroke={stroke}
      strokeWidth="4"
      {...props}>
      <path d="M32.51 27.83A14.4 14.4 0 0 1 30 24.9a12.6 12.6 0 0 1-1.35-4.81v-4.94A10.81 10.81 0 0 0 19.21 4.4V3.11a1.33 1.33 0 1 0-2.67 0v1.31a10.81 10.81 0 0 0-9.33 10.73v4.94a12.6 12.6 0 0 1-1.35 4.81 14.4 14.4 0 0 1-2.47 2.93 1 1 0 0 0-.34.75v1.36a1 1 0 0 0 1 1h27.8a1 1 0 0 0 1-1v-1.36a1 1 0 0 0-.34-.75" />
      <path d="M18,34.28A2.67,2.67,0,0,0,20.58,32H15.32A2.67,2.67,0,0,0,18,34.28Z" />
    </Box>
  );
};
export default NotificationBell;
