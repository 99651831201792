import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Text, Flex, Heading, Button, Link, Label, Checkbox } from "@theme-ui/components";

import Layout from "../../containers/Layout";
import { useHistory } from "react-router-dom";
import { FormInput, SelfcareAmount } from "../../components/base";
import CarriersMenu, { carriers } from "./CarriersMenu";
import { CBO_RESOLUTION_TYPE_CODE, STEP1 } from "../../common/Constants";
import GoBack from "../../components/GoBack";
import {
  addDocument,
  moveToVerifyProblem,
  clearAddDocumentStatus,
  clearMoveToVerifyProblemStatus,
  setUserError,
  getCboTicket,
} from "../../redux/slices/UserSlice";
import { StyledModalMessage } from "../../components/modals";
import SubmitButton from "../../components/SubmitButton";
import { isMobileView } from "../../common/Utilities";
import SubHeader from "../../components/SubHeader";
import FormatPhone from "../../components/FormatPhone";
import { usePhoneNumber } from "../../hooks/usePhoneNumber";

const ContractBuyout = () => {
  const intl = useIntl();
  const history = useHistory();

  const {
    account,
    cboTTicket,
    add_document_status,
    move_to_verify_problem_stuatus,
    userError,
    master,
  } = useSelector(state => state.user);
  const providerPhoneNumber = usePhoneNumber(master?.udfCategory);
  const defaultCarrier = carriers[0];
  const [applyNowVisibility, setApplyNowVisibility] = useState(false);
  const [formVisbility, setFormVisbility] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(defaultCarrier);
  const dispatch = useDispatch();
  const isMobile = isMobileView();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const reactHookFormHandle = { register, errors };
  const uploadedFiles = watch("files");

  const getMessageByStep = () => {
    //ELIGIBLE CASE
    if (cboTTicket?.step === STEP1 && applyNowVisibility) {
      return {
        title: "lbl.eligible",
        content: "lbl.cbo_eligible_form",
        link: intl.formatMessage({ id: "link.information_on_contract_buyout" }),
      };
    }
    //APPLY CASE
    else if (cboTTicket?.step === STEP1 && formVisbility) {
      return {
        title: "lbl.apply_cbo",
        content: "lbl.apply_content",
      };
    }
    //REJECT CASE
    else if (
      cboTTicket?.resolutionTypeCode &&
      cboTTicket?.resolutionTypeCode !== CBO_RESOLUTION_TYPE_CODE.APPR.CODE
    ) {
      return {
        title: CBO_RESOLUTION_TYPE_CODE[cboTTicket?.resolutionTypeCode].TITLE,
        content: CBO_RESOLUTION_TYPE_CODE[cboTTicket?.resolutionTypeCode].CONTENT,
        phoneNumber: account.phoneNumber,
      };
    }
    //APPROVE CASE
    else if (cboTTicket?.resolutionTypeCode === CBO_RESOLUTION_TYPE_CODE.APPR.CODE) {
      return {
        title: CBO_RESOLUTION_TYPE_CODE.APPR.TITLE,
        content: CBO_RESOLUTION_TYPE_CODE.APPR.CONTENT,
        amount: cboTTicket?.amount,
        phoneNumber: account.phoneNumber,
      };
    }
    //PROGESS CASE
    else if (cboTTicket?.status === "IN_PROGRESS") {
      return {
        title: "lbl.in_progress",
        content: "lbl.review_ticket",
      };
    }
  };

  useEffect(() => {
    //form visibility by case (eligibe, apply, reject, progress)
    if (cboTTicket?.step === STEP1) {
      setApplyNowVisibility(true);
      setFormVisbility(false);
    } else if (
      cboTTicket?.resolutionTypeCode &&
      cboTTicket?.status === "IN_PROGRESS" &&
      (cboTTicket?.resolutionTypeCode === CBO_RESOLUTION_TYPE_CODE.DSPRFI.CODE ||
        cboTTicket?.resolutionTypeCode === CBO_RESOLUTION_TYPE_CODE.DSPRCF.CODE)
    ) {
      setFormVisbility(true);
    } else {
      setFormVisbility(false);
    }
  }, [cboTTicket?.step, cboTTicket?.status, cboTTicket?.resolutionTypeCode]);

  useEffect(() => {
    if (add_document_status === "success") {
      dispatch(
        moveToVerifyProblem({
          accountCode: account.accountCode,
          processId: cboTTicket?.processId,
          isFirstStep: cboTTicket.step === STEP1,
        })
      );
    }
  }, [add_document_status, dispatch, cboTTicket, account]);

  const onSubmit = values => {
    if (add_document_status !== "success") {
      dispatch(
        addDocument({
          accountCode: account.accountCode,
          processId: cboTTicket?.processId,
          file: values.files[0],
        })
      );
    } else {
      dispatch(
        moveToVerifyProblem({
          accountCode: account.accountCode,
          processId: cboTTicket?.processId,
          isFirstStep: cboTTicket.step === STEP1,
        })
      );
    }
  };

  const onApprove = () => {
    dispatch(clearAddDocumentStatus());
    dispatch(clearMoveToVerifyProblemStatus());
    dispatch(setUserError(null));
    dispatch(getCboTicket(account.accountCode));
  };

  const onError = () => {
    dispatch(setUserError(null));
    if (move_to_verify_problem_stuatus === "failed") {
      history.push("/home");
      onApprove();
    }
  };

  const CBOForm = () => {
    const intl = useIntl();
    const FILE_MAX_SIZE = 1000000;
    const ACCEPTED_FILE_FORMATS = ["pdf", "png", "jpeg", "jpg"];

    return (
      <Flex sx={{ flexDirection: "column", minWidth: "40%", maxWidth: ["100%", "100%", "40%"] }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CarriersMenu selectedCarrier={selectedCarrier} setSelectedCarrier={setSelectedCarrier} />

          <Flex sx={{ flexDirection: "column", py: "medium", gap: "0.5rem" }}>
            <FormattedMessage id="lbl.display_as" />
            <Text
              mb="tiny"
              color="textDark"
              sx={{ fontWeight: "semiBold", alignSelf: "flex-start" }}>
              <FormattedMessage values={{ br: <br /> }} id={selectedCarrier?.msg} />
            </Text>
          </Flex>
          <Text mt="default" variant="copyright">
            <FormattedMessage id="lbl.cbo_form_note" values={{ b: value => <b>{value}</b> }} />
          </Text>
          <Flex
            sx={{
              alignItems: "baseline",
              flexDirection: ["column", "row", "row"],
              textAlign: "center",
            }}>
            <FormInput
              name="files"
              type="file"
              errorLabel="lbl.browse"
              errorProps={{ sx: { alignSelf: "center", textAlign: "left", ml: "default" } }}
              wrapperProps={{ sx: { width: ["100%", "fit-content"], mx: ["auto", 0] } }}
              validations={{
                validate: {
                  length: value => {
                    return (
                      (value.length === 0 &&
                        intl.formatMessage({ id: "err.upload_file_mandatory" })) ||
                      true
                    );
                  },

                  maxSize: value => {
                    return (
                      (value[0].size >= FILE_MAX_SIZE &&
                        intl.formatMessage({ id: "err.upload_file_max_size" })) ||
                      true
                    );
                  },
                  extension: value => {
                    let extension = String(value[0].name).split(".").pop();
                    return (
                      (!ACCEPTED_FILE_FORMATS.includes(extension) &&
                        intl.formatMessage({ id: "err.no_accepted_file_format" })) ||
                      true
                    );
                  },
                },
              }}
              {...reactHookFormHandle}
            />
            {uploadedFiles?.length > 0 && !errors.files && (
              <Text
                variant="copyright"
                ml={[0, "default"]}
                mt={["small", 0]}
                sx={{
                  width: ["100%", "fit-content"],
                  maxWidth: ["100%", "14rem"],
                  wordWrap: "break-word",
                  textAlign: "center",
                  mx: [0, 0, "auto"],
                }}>
                {uploadedFiles[0].name}
              </Text>
            )}
          </Flex>
          <Label
            mt="default"
            sx={{
              alignItems: "center",
              justifyContent: ["center", "left", "left"],
              color: errors.isAgreeChecked ? "textError" : "primary",
            }}>
            <Checkbox
              name="isAgreeChecked"
              sx={{
                color: errors.isAgreeChecked ? "textError" : "primary",
                "input:focus ~ &": {
                  color: errors.isAgreeChecked ? "textError" : "primary",
                },
              }}
              {...register("isAgreeChecked", {
                required: value => value === false,
              })}
            />
            <Flex sx={{ gap: "tiny" }}>
              <FormattedMessage id="lbl.i_agree_to_the" />
              <Link
                sx={{ color: errors.isAgreeChecked ? "textError" : "primary" }}
                href={intl.formatMessage({ id: "link.information_on_contract_buyout" })}
                target="_blank">
                <FormattedMessage id="lbl.terms_conditions" />
              </Link>
            </Flex>
          </Label>
          <Flex
            my="large"
            sx={{
              justifyContent: "space-between",
              flexDirection: ["column-reverse", "row"],
              alignItems: "center",
            }}>
            <Button
              variant="secondary"
              mt={["default", 0]}
              onClick={() => {
                setSelectedCarrier(defaultCarrier);
                setFormVisbility(false);
                setApplyNowVisibility(true);
                reset();
              }}>
              <FormattedMessage id="lbl.cancel" />
            </Button>
            <SubmitButton
              isLoading={
                move_to_verify_problem_stuatus === "loading" || add_document_status === "loading"
              }
              width="14rem"
              text="lbl.submit_application"
            />
          </Flex>
        </form>
      </Flex>
    );
  };

  const ApplyNow = () => {
    return (
      <Flex
        sx={{
          width: "50%",
          alignSelf: "center",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}>
        <SubmitButton
          sx={{ alignSelf: "center", width: "14rem", px: "small", mb: ["default", "default", 0] }}
          text="lbl.apply_now"
          onClick={() => {
            setFormVisbility(true);
            setApplyNowVisibility(false);
          }}
        />
      </Flex>
    );
  };

  const Message = params => {
    const { content, phoneNumber, amount, link } = params.messages;
    return (
      <Flex sx={{ flexDirection: "column" }}>
        {content && (
          <Text sx={{ textAlign: "justify" }}>
            {intl.formatMessage(
              { id: content },
              {
                phone: (
                  <Text sx={{ whiteSpace: "nowrap" }}> {<FormatPhone number={phoneNumber} />}</Text>
                ),
                amount: <SelfcareAmount amount={amount} />,
                p: value => <p>{value}</p>,
                a: value => (
                  <Link variant="link" href={link} sx={{ lineBreak: "anywhere" }}>
                    {value}
                  </Link>
                ),
                br: <br />,
                phoneNumber: providerPhoneNumber,
                category: master?.udfCategory,
              }
            )}
          </Text>
        )}
      </Flex>
    );
  };

  return (
    <Layout>
      <SubHeader />
      <Flex sx={{ width: "100%", flexDirection: "column" }}>
        <Heading>
          <FormattedMessage
            id="lbl.contract_buyout"
            values={{
              phoneNumber: <FormatPhone number={account?.phoneNumber} />,
              isMobile,
              br: <br />,
            }}
          />
        </Heading>

        <Flex
          sx={{ gap: "2rem", flexDirection: ["column", "column", "row"] }}
          variant="layout.section">
          {cboTTicket && <Message messages={getMessageByStep()} />}
          {formVisbility && <CBOForm />}
          {applyNowVisibility && <ApplyNow />}
        </Flex>

        <GoBack onBack={() => history.goBack()} />

        <StyledModalMessage
          isOpen={!!userError}
          message={
            <>
              {add_document_status === "failed" && <FormattedMessage id="err.add_document" />}
              {move_to_verify_problem_stuatus === "failed" && (
                <FormattedMessage id="err.move_to_verify_prob" />
              )}
            </>
          }
          onRequestClose={onError}
          type="error">
          <Button onClick={onError}>
            <FormattedMessage id="lbl.Try_Again" />
          </Button>
        </StyledModalMessage>

        <StyledModalMessage
          isOpen={add_document_status === "success" && move_to_verify_problem_stuatus === "success"}
          message={<FormattedMessage id="lbl.cbo_confirmation" />}
          onRequestClose={onApprove}
          type="error">
          <Button
            onClick={() => {
              onApprove();
              history.goBack();
            }}>
            <FormattedMessage id="lbl.ok" />
          </Button>
        </StyledModalMessage>
      </Flex>
    </Layout>
  );
};

export default ContractBuyout;
