import React from "react";
import { Text, Flex, Button } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentItem = ({ ...props }) => {
  const history = useHistory();

  const { billing_summary_status } = useSelector(state => state.billing);

  if (billing_summary_status !== "success") {
    return (
      <Flex
        {...props}
        py="small"
        sx={{
          width: ["100%", "100%", "13rem", "14rem"],
          justifyContent: "center",
          alignItems: "center",
          height: "6.375rem",
          flexDirection: "column",
          cursor: "pointer",
          ...props.sx,
        }}
      />
    );
  }

  return (
    <Flex
      {...props}
      py="small"
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        stroke: "primary",
        color: "primary",
        ":hover": { stroke: "secondary", color: "secondary" },
        ...props.sx,
      }}>
      <Button
        onClick={() => history.push("/makePayment")}
        variant="primary"
        px="large"
        py="small"
        sx={{
          width: "fit-content",
        }}>
        <Text color="textLight">
          <FormattedMessage id="lbl.make_payment" />
        </Text>

        <Text
          mt="-0.25rem"
          ml="small"
          color="sunshine"
          sx={{
            fontWeight: "semiBold",
            fontSize: [4, 4, 6, 6],
            transition: "100ms ease-in",
          }}>
          {"›"}
        </Text>
      </Button>

      <Flex mt="default" sx={{ alignItems: "center", justifyContent: "center" }}>
        <SelfcareIcon stroke="inherit" name="lock" sx={{ width: "1rem", height: "1rem" }} />
        <Text variant="note" color="inherit">
          <FormattedMessage id="lbl.secure_transaction" />
        </Text>
      </Flex>
    </Flex>
  );
};

export default PaymentItem;
