import React from "react";

const BackMobile = ({ color = "#FFF", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 18.998 17.495"
    {...props}>
    <path
      stroke={color}
      strokeWidth={1}
      d="M312.01,165.926v-2.045a.4.4,0,0,0-.6-.346l-4.848,2.8a.4.4,0,0,0,0,.693l4.848,2.8a.4.4,0,0,0,.6-.346v-2.054h11.85v12.05H312.01v1.5h12.95a.4.4,0,0,0,.4-.4v-14.25a.4.4,0,0,0-.4-.4Z"
      transform="translate(-306.362 -163.481)"
    />
  </svg>
);

export default BackMobile;
