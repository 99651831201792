import React, { useContext } from "react";

import { Flex, Image, Text } from "@theme-ui/components";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { BrandingContext } from "../../contexts/BrandingContext";
import NavMenu from "../NavMenu/NavMenu";
import User from "../User";
import { useSelector } from "react-redux";
import { UDF_CATEGORIES } from "../../common/Constants";
import { SelfcareIcon } from "../icons";
import { Providers } from "../../common/Providers";

const Header = props => {
  const { logo, provider, switchTheme } = useContext(BrandingContext);
  const intlParams = useContext(SelfcareIntlContext);
  const { master } = useSelector(state => state.user);
  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;

  const changeProvider = () => {
    let prvList = Object.keys(Providers);
    let idx = prvList.indexOf(provider);
    let nextProvider = prvList[(idx + 1) % prvList.length];
    switchTheme(nextProvider);
  };
  const RenderLogo = () => {
    return (
      <Image
        sx={{
          width: ["3rem", "13rem", "11.375rem", "13.375rem"],
          height: ["3rem", "13rem", "3.875rem"],
        }}
        src={logo}
        mr={["default", "small", "default", "2rem"]}
        onClick={() => changeProvider()}
      />
    );
  };

  const LanguageSwitch = () => {
    const { language } = useContext(BrandingContext);
    return (
      <Flex
        sx={{ alignItems: "center", cursor: "pointer", mr: "1rem", width: "6rem" }}
        onClick={() => {
          intlParams.switchLanguageTo(intlParams.nextLanguage(intlParams.locale));
        }}>
        <SelfcareIcon name="language" sx={{ cursor: "pointer" }} />
        <Text sx={{ fontSize: [0, 1], color: "textLight", mx: "tiny" }}>
          {language.SUPPORTED_LOCALES[intlParams.nextLanguage(intlParams.locale)]}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex
      {...props}
      bg="headerBg"
      sx={{
        alignItems: "center",
        backgroundSize: "cover",
        zIndex: 999,
        width: "100%",
        height: ["3rem", "4rem", "7rem"],
        position: "fixed",
        top: 0,
      }}
      px={["default", "default", "huge", "mobileModalExtraOffset"]}>
      <RenderLogo />

      <NavMenu />

      {master && (
        <Flex
          ml="auto"
          sx={{
            alignItems: "center",
            width: ["30%", "20%", "15%", "20%"],
            justifyContent: "flex-end",
          }}>
          {isResidential && (
            <Flex sx={{ display: ["none", "none", "inline"] }}>
              <LanguageSwitch />
            </Flex>
          )}

          <User ml={["small", "small", "small", "default"]} />
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
