import React, { useState, useEffect } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Flex, Text, Grid, Divider, Link } from "@theme-ui/components";
import { SelfcareIcon } from "../icons";
import { useSelector } from "react-redux";
import { SelfcareAmount } from "../base";
import DeviceDetailsSkeleton from "./DeviceDetailsSkeleton";

const DeviceDetailsCard = () => {
  const { deviceDetails, device_details_status, account } = useSelector(state => state.user);
  const [deviceDetailsLevel, setDeviceDetailsLevel] = useState("noDetails");
  const intl = useIntl();
  // options: full, byod, noDetails, retailPrice

  useEffect(() => {
    if (device_details_status === "success") {
      determineDetailsLevel(deviceDetails, setDeviceDetailsLevel);
    }
  }, [deviceDetails, device_details_status]);

  function determineDetailsLevel(deviceDetails, setDeviceDetailsLevel) {
    if (deviceDetails !== null && deviceDetails.accountGoodsResponse !== null) {
      if (deviceDetails.installmentPlanResponse !== null) {
        setDeviceDetailsLevel("full");
      } else if (deviceDetails.accountGoodsResponse.cpe) {
        setDeviceDetailsLevel("byod");
      } else {
        setDeviceDetailsLevel("retailPrice");
      }
    } else {
      setDeviceDetailsLevel("noDetails");
    }
  }

  function getIcon(deviceDetailsLevel) {
    switch (deviceDetailsLevel) {
      case "full":
      case "retailPrice":
        return "deviceDetails";
      default:
        return "byodDetails";
    }
  }

  function getTextForIcon(deviceDetailsLevel) {
    switch (deviceDetailsLevel) {
      case "full":
      case "retailPrice":
        if (
          deviceDetails &&
          deviceDetails.accountGoodsResponse &&
          deviceDetails.accountGoodsResponse.modelDescription
        ) {
          return deviceDetails.accountGoodsResponse.modelDescription;
        } else return <FormattedMessage id="lbl.byod" />;
      default:
        return <FormattedMessage id="lbl.byod" />;
    }
  }

  function getHeaderText(deviceDetailsLevel) {
    switch (deviceDetailsLevel) {
      case "retailPrice":
        return <FormattedMessage id="lbl.phone_balance" />;

      default:
        return <FormattedMessage id="lbl.phone_model" />;
    }
  }

  function getMainText(deviceDetailsLevel) {
    switch (deviceDetailsLevel) {
      case "retailPrice":
        if (
          deviceDetails &&
          deviceDetails.accountGoodsResponse &&
          deviceDetails.accountGoodsResponse.modelDescription
        ) {
          return (
            <Text sx={{ fontWeight: "semiBold" }}>
              <SelfcareAmount amount={0} />
            </Text>
          );
        } else return <FormattedMessage id="lbl.customer_provided_equipment" />;

      default:
        return <FormattedMessage id="lbl.customer_provided_equipment" />;
    }
  }
  if (device_details_status === "loading" || !account) {
    return <DeviceDetailsSkeleton />;
  }

  const easyTabFlexSupported =
    deviceDetails?.installmentPlanResponse?.flexReturnDate &&
    deviceDetails?.installmentPlanResponse?.totalDeferredAmount > 0;

  return (
    deviceDetails !== null &&
    device_details_status === "success" && (
      <Flex sx={{ flexDirection: ["column", "row"] }}>
        <Flex
          //for the icon
          mr={["small", "default", "large"]}
          px={["small", "small", "default"]}
          sx={{
            flexShrink: 0,
            flexDirection: "column",
            borderRadius: "card",
            height: ["12.875rem", "12rem", "12rem", "12.875rem"],
            width: ["12.875rem", "8rem", "12rem", "14.875rem"],
            backgroundColor: "lightBlue",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}>
          <Flex
            mt="default"
            py={["small", "small", "small", "default"]}
            sx={{
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
            }}>
            <SelfcareIcon
              sx={{ width: ["8rem", "3rem", "6rem", "8rem"] }}
              name={getIcon(deviceDetailsLevel)}
            />

            <Text
              sx={{
                textAlign: "center",
                color: "primary",
                fontSize: [1, 1, 1, 2],
                fontWeight: "medium",
              }}>
              {getTextForIcon(deviceDetailsLevel)}
            </Text>
          </Flex>
        </Flex>

        {deviceDetailsLevel === "full" ? (
          renderFullDetails()
        ) : (
          <Flex
            mt="default"
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Text color="disabledColor">{getHeaderText(deviceDetailsLevel)}</Text>
            <Text ml="small">{getMainText(deviceDetailsLevel)}</Text>
          </Flex>
        )}
      </Flex>
    )
  );

  function renderFullDetails() {
    return (
      deviceDetails.installmentPlanResponse && (
        <Flex
          my={["default", "large"]}
          sx={{
            flexDirection: "column",
            borderRadius: "9px",
            width: "100%",
          }}>
          <Flex
            sx={{
              justifyContent: "center",
              height: ["2rem", "5rem"],
              width: "100%",
              alignItems: "baseline",
            }}>
            <Text mr="small" color="disabledColor">
              <FormattedMessage
                id={easyTabFlexSupported ? "lbl.monthly_easyTab_flex" : "lbl.monthly_easyTab"}
                values={{
                  sup: chunks => (
                    <Text as="sup" sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>
            <Text sx={{ fontWeight: "semiBold" }}>
              <SelfcareAmount amount={deviceDetails.installmentPlanResponse.periodicAmount} />
              <FormattedMessage id="lbl.monthly_short" />
            </Text>
          </Flex>

          <Divider mr="default" sx={{ color: "border", border: "1px solid" }} />

          <Grid
            columns={["1.7fr 1fr", "1.7fr 1fr 1.7fr 1fr"]}
            color="disabledColor"
            sx={{ textAlign: "left", alignItems: "end", gap: "smallish" }}>
            <Text color="disabledColor" mt="medium">
              <FormattedMessage
                id={easyTabFlexSupported ? "lbl.easyTab_flex_duration" : "lbl.total_duration"}
                values={{
                  sup: chunks => (
                    <Text as="sup" sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>

            <Text>
              {deviceDetails.installmentPlanResponse.totalMonths + " "}
              <FormattedMessage id="lbl.months" />
            </Text>

            <Text color="disabledColor">
              <FormattedMessage id="lbl.month_left" />
            </Text>
            <Text>
              {deviceDetails.installmentPlanResponse.remainingMonths + " "}
              <FormattedMessage id="lbl.months" />
            </Text>

            <Text color="disabledColor">
              <FormattedMessage id="lbl.remaining" />
            </Text>
            <Text sx={{ fontWeight: "semiBold" }}>
              <SelfcareAmount amount={deviceDetails.installmentPlanResponse.remainingAmount} />
            </Text>

            <Text color="disabledColor">
              <FormattedMessage
                id={easyTabFlexSupported ? "lbl.easytab_flex_start_date" : "lbl.start_date"}
                values={{
                  sup: chunks => (
                    <Text as="sup" sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>
            <Text sx={{ fontWeight: "semiBold" }}>
              <FormattedDate
                value={deviceDetails.installmentPlanResponse.startDate}
                dateStyle="medium"
              />
            </Text>

            {easyTabFlexSupported ? (
              <>
                <Text color="disabledColor" mr="default">
                  <FormattedMessage
                    id="lbl.easyTab_flex_deferred_amount"
                    values={{
                      sup: chunks => (
                        <Text
                          as="sup"
                          sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
                <Text sx={{ fontWeight: "semiBold" }}>
                  <SelfcareAmount
                    amount={deviceDetails.installmentPlanResponse.totalDeferredAmount}
                  />
                </Text>
                <Text color="disabledColor" mr="default">
                  <FormattedMessage
                    id="lbl.easyTab_flex_return_date"
                    values={{
                      sup: chunks => (
                        <Text
                          as="sup"
                          sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
                <Text sx={{ fontWeight: "semiBold" }}>
                  <FormattedDate
                    value={deviceDetails.installmentPlanResponse.flexReturnDate}
                    dateStyle="medium"
                  />
                </Text>
              </>
            ) : (
              <>
                <Text color="disabledColor" mr="default">
                  <FormattedMessage
                    id="lbl.expiration_date"
                    values={{
                      sup: chunks => (
                        <Text
                          as="sup"
                          sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
                <Text sx={{ fontWeight: "semiBold" }}>
                  <FormattedDate
                    value={deviceDetails.installmentPlanResponse.expirationDate}
                    dateStyle="medium"
                  />
                </Text>
              </>
            )}
          </Grid>
          {easyTabFlexSupported ? (
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                gap: "default",
                mt: "large",
              }}>
              <Text color="disabledColor">
                <FormattedMessage
                  id="lbl.easyTab_flex"
                  values={{
                    sup: chunks => (
                      <Text as="sup" sx={{ display: "inline", fontSize: "45%", color: "disabled" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              </Text>
              <Link
                mt="small"
                href={intl.formatMessage({ id: "link.terms_and_conditions" })}
                target="_blank"
                variant="nav">
                <FormattedMessage id="lbl.terms_conditions" />
              </Link>
            </Flex>
          ) : null}
        </Flex>
      )
    );
  }
};

export default DeviceDetailsCard;
