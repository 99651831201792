import React from "react";
import { useIntl } from "react-intl";

import { Flex, Input, Text } from "@theme-ui/components";

import { SelfcareIcon } from "../icons";

const SearchBar = ({ onChangeFilter, searchTerm, clearFilter, ...props }) => {
  const intl = useIntl();

  return (
    <Flex sx={{ width: ["100%", "80%", "30rem"], alignItems: "center" }} {...props}>
      <Input
        onChange={onChangeFilter}
        value={searchTerm}
        placeholder={intl.formatMessage({ id: "lbl.search_service" })}
        maxLength="30"
        type="text"
        bg="searchBarBg"
        sx={{ height: "3rem" }}
      />
      <Flex ml="-2.6rem">
        {searchTerm === "" ? (
          <SelfcareIcon name="search" ml="small" />
        ) : (
          <Text
            sx={{
              color: "primary",
              cursor: "pointer",
              ":hover": { color: "secondary" },
            }}
            onClick={clearFilter}>
            &#10006;
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default SearchBar;
