import React from "react";

const Back = ({ stroke = "#001E62" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 88 88"
    fill="none"
    stroke={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="0.25rem"
    width="36"
    height="36">
    <g>
      <g id="Back">
        <polyline points="49.8 55.6 38.2 44 49.8 32.4" />
      </g>
    </g>
  </svg>
);
export default Back;
