import React, { useState, useEffect, useRef } from "react";
import { useDatepicker, START_DATE, END_DATE } from "@datepicker-react/hooks";
import { FormattedMessage, useIntl } from "react-intl";

import { Text, Flex } from "@theme-ui/components";

import DatepickerContext from "../../contexts/DatepickerContext";
import OutsideClickHandler from "../OutsideClickHandler";
import useWindowSize from "../../hooks/useWindowSize";
import { getISODate, isMobileView } from "../../common/Utilities";
import Month from "./Month";

const Datepicker = ({ minDate, maxDate, state, setState, ...props }) => {
  const endDateRef = useRef();
  const size = useWindowSize();
  const startDateRef = useRef();
  const { formatDate } = useIntl();

  const [dateWidth, setDateWidth] = useState("");
  const [showDatepicker, setShowDatepicker] = useState(false);
  const isStartDateFocused =
    !state.endDate && state.focusedInput && state.focusedInput.includes("start");
  const isMobile = isMobileView();

  useEffect(() => {
    if (state.startDate && state.endDate) setShowDatepicker(false);
  }, [state]);

  const handleDateChange = data => {
    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }
  };

  useEffect(() => {
    let width;
    if (startDateRef?.current && endDateRef?.current) {
      width =
        endDateRef?.current?.getBoundingClientRect().right -
        startDateRef?.current?.getBoundingClientRect().left;
      setDateWidth(`${width}px`);
    }
  }, [size]);

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    numberOfMonths: isMobile ? 1 : 2,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    minBookingDate: minDate,
    maxBookingDate: maxDate,
  });

  const changeEndDate = () => {
    setState({
      startDate: state.startDate,
      endDate: null,
      focusedInput: state.startDate ? END_DATE : START_DATE,
    });
  };

  const changeStartDate = () => {
    setState({
      startDate: state.startDate,
      endDate: null,
      focusedInput: START_DATE,
    });
  };

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}>
      <Flex
        {...props}
        sx={{
          flexDirection: "column",
          width: ["100%", "fit-content", "fit-content"],
          ...props.sx,
        }}>
        <OutsideClickHandler
          onClickOutside={() => {
            setShowDatepicker(false);
          }}>
          <Flex
            sx={{
              flexDirection: ["column", "row", "row", "row"],
              alignItems: ["center", "left", "left"],
            }}>
            <Flex
              ref={startDateRef}
              onClick={() => {
                setShowDatepicker(!showDatepicker);
                changeStartDate();
              }}
              color="deviceDetailsMainLable"
              sx={{
                cursor: "pointer",
                width: "14rem",
                height: "3rem",
                border: "solid 1px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                userSelect: "none",
                borderColor: "border",
                borderBottom: isStartDateFocused && "4px solid ",
                borderBottomColor: isStartDateFocused && "primary",
                borderRadius: "card",
              }}>
              <Text variant="" color="deviceDetailsMainLable" sx={{ fontSize: [3, 5, 5] }}>
                {state.startDate ? (
                  formatDate(getISODate(state.startDate), { dateStyle: "medium" })
                ) : (
                  <FormattedMessage id="tooltip.start_date" />
                )}
              </Text>
            </Flex>
            {!isMobile && (
              <Flex
                color="deviceDetailsMainLable"
                sx={{
                  width: "4rem",
                  height: "3rem",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 7,
                }}>
                {">"}
              </Flex>
            )}

            <Flex
              ref={endDateRef}
              onClick={() => {
                changeEndDate();
                setShowDatepicker(!showDatepicker);
              }}
              mt={["default", 0]}
              sx={{
                cursor: "pointer",
                width: "14rem",
                height: "3rem",
                border: "solid 1px",
                justifyContent: "center",
                alignItems: "center",
                userSelect: "none",
                flexDirection: "column",
                borderColor: "border",
                borderBottom:
                  state.focusedInput && state.focusedInput.includes("end") && "4px solid ",
                borderBottomColor:
                  state.focusedInput && state.focusedInput.includes("end") && "primary",
                borderRadius: "card",
              }}>
              <Text variant="" color="deviceDetailsMainLable" sx={{ fontSize: [3, 5, 5] }}>
                {state.endDate ? (
                  formatDate(getISODate(state.endDate), { dateStyle: "medium" })
                ) : (
                  <FormattedMessage id="tooltip.end_date" />
                )}
              </Text>
            </Flex>
          </Flex>

          {showDatepicker && (
            <Flex
              px="default"
              bg="contentBg"
              sx={{
                width: dateWidth ? dateWidth : "32rem",
                border: "1px solid",
                borderRadius: "card",
                borderColor: "border",
                position: "absolute",
                zIndex: 1,
                mt: isStartDateFocused && isMobile ? "-4rem" : 0,
                left: isMobile ? "50%" : "initial",
                transform: isMobile ? "translate(-50%, 0)" : "initial",
                justifyContent: "space-between",
              }}>
              {activeMonths.map((month, index) => (
                <Month
                  nextMonth={goToNextMonths}
                  prevMonth={goToPreviousMonths}
                  key={`${month.year}-${month.month}`}
                  year={month.year}
                  month={month.month}
                  firstDayOfWeek={firstDayOfWeek}
                  index={index}
                  isMobileView={isMobile}
                />
              ))}
            </Flex>
          )}
        </OutsideClickHandler>
      </Flex>
    </DatepickerContext.Provider>
  );
};

export default Datepicker;
