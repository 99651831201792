import React, { useState, useEffect } from "react";

import { Input, Flex, Label, Text } from "@theme-ui/components";

import { SelfcareIcon } from "../icons";
import { useCombobox } from "downshift";
import MenuOption from "../MenuOption";

const Autocomplete = ({
  items,
  label,
  placeHolder,
  onSelectItem,
  noItemsMessage,
  noItemFoundMessage,
}) => {
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const onSelect = event => {
    const item = event.selectedItem;
    if (item) {
      onSelectItem(item.label);
    } else {
      onSelectItem("");
    }
  };

  function getItemFilter(inputValue) {
    const lowerCasedInputValue = inputValue
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    (!inputValue || inputValue === "") && onSelectItem("");

    return function itemsFilter(item) {
      return (
        !inputValue ||
        item.value
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(lowerCasedInputValue)
      );
    };
  }

  const {
    reset,
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    selectedItem,
    openMenu,
    closeMenu,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      (!inputValue || inputValue === "") && selectItem(null);
      setFilteredItems(items.filter(getItemFilter(inputValue)));
    },
    items: filteredItems,
    onSelectedItemChange: onSelect,
    itemToString(item) {
      return item ? item.value : "";
    },
  });

  return (
    <Flex
      sx={{
        flexDirection: "column",
        width: ["100%", "20rem"],
        alignSelf: ["center", "initial"],
      }}>
      {label && (
        <Label data-testid="label" {...getLabelProps()}>
          {label}
        </Label>
      )}
      <Flex sx={{ alignItems: "center" }} {...getToggleButtonProps()}>
        <Input
          placeholder={placeHolder}
          px="default"
          {...getInputProps({
            onClick: isOpen ? closeMenu : openMenu,
          })}
        />
        <Flex ml="-2rem">
          {selectedItem ? (
            <Text
              sx={{
                color: "primary",
                cursor: "pointer",
                ":hover": { color: "secondary" },
              }}
              onClick={reset}>
              &#10006;
            </Text>
          ) : (
            <SelfcareIcon
              name={isOpen ? "upArrow" : "downArrow"}
              sx={{ ml: "-0.8rem", cursor: "pointer" }}
            />
          )}
        </Flex>
      </Flex>

      <Flex {...getMenuProps()}>
        {isOpen && filteredItems.length > 0 && (
          <Flex
            id="filteredList"
            mt="small"
            bg="contentBg"
            sx={{
              position: "absolute",
              flexDirection: "column",
              maxHeight: "12rem",
              width: ["90%", "20rem"],
              overflow: "auto",
              overflowX: "hidden",
              border: "solid 1px",
              borderColor: "border",
              boxShadow: "light",
              borderRadius: "card",
              zIndex: "1",
            }}>
            {filteredItems.map((item, index) => (
              <MenuOption
                key={index}
                data-testid="itemBtn "
                text={item.value}
                isHighlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              />
            ))}
          </Flex>
        )}
      </Flex>

      {filteredItems.length == 0 && noItemFoundMessage && (
        <Text ml="0.5rem" mt="0.5rem" color="textDark">
          {noItemFoundMessage}
        </Text>
      )}

      {noItemsMessage && (
        <Text mt="medium" color="textDark">
          {noItemsMessage}
        </Text>
      )}
    </Flex>
  );
};

export default Autocomplete;
