import React from "react";
import { FormattedMessage } from "react-intl";

import { Flex, Text } from "@theme-ui/components";

import { SelfcareIcon } from "../icons";

const Remove = ({ onRemove = null, ...props }) => {
  return (
    <Flex
      mr="1.5rem"
      sx={{
        position: "relative",
        alignSelf: "end",
        zIndex: 2,
        alignItems: "center",
        ":hover": {
          cursor: "pointer",
        },
        ...props.sx,
      }}
      onClick={onRemove}>
      <SelfcareIcon
        name="remove"
        sx={{
          ":hover": {
            cursor: "pointer",
          },
        }}
      />
      <Text ml="0.5rem" variant="tablelink" sx={{ fontWeight: "semiBold" }}>
        <FormattedMessage id="lbl.remove" />
      </Text>
    </Flex>
  );
};

export default Remove;
