const MILLISEC_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

const maskString = (value = "") => {
  let maskedPart = "*".repeat(4);
  if (!value) {
    return maskedPart;
  }

  let startOfVisiblePart = value.length > 1 ? value.length - 2 : 0;
  let lastPart = value.substring(startOfVisiblePart);

  return [maskedPart + lastPart];
};

function removeTimezoneFromDate(input) {
  var date = new Date(input);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  var date2 = new Date(date.getTime() + userTimezoneOffset);
  return date2.getTime();
}

function getDateWithoutTimezone(inputDate) {
  if (!inputDate) {
    return inputDate;
  }

  return new Date(removeTimezoneFromDate(inputDate));
}

function getDaysUntil(toDateMilliseconds) {
  const timeDiffMiliseconds =
    removeTimezoneFromDate(toDateMilliseconds) - removeTimezoneFromDate(new Date());
  return Math.ceil(timeDiffMiliseconds / (1000 * 3600 * 24));
}

function getDaysUntilFromDate(fromDateMilliseconds, toDateMilliseconds) {
  const timeDiffMiliseconds =
    removeTimezoneFromDate(toDateMilliseconds) - removeTimezoneFromDate(fromDateMilliseconds);
  return Math.ceil(timeDiffMiliseconds / (1000 * 3600 * 24));
}

function getRemainingDaysPIA(piaExpirationDate) {
  return getDaysUntil(piaExpirationDate) + 1;
}

function getDaysUntilNextBP(billingPeriodEndDateString) {
  return getDaysUntil(Date.parse(billingPeriodEndDateString)) + 1;
}

function getFullName(contact) {
  if (!contact || !contact.name) {
    return "";
  }

  let result = contact.name.first ? contact.name.first + " " : "";
  result += contact.name.middle ? contact.name.middle + " " : "";
  result += contact.name.last ? contact.name.last + " " : "";

  return result;
}

function buildAddress(contact) {
  if (!contact) {
    return "";
  }
  return contact.addressType === "STRUCTURED"
    ? buildStructuredAddress(contact.structuredAddress)
    : buildFreeFormAddress(contact.address);
}

function buildStructuredAddress(address) {
  if (!address) {
    return "";
  }

  let result = address.floor ? address.floor + ", " : "";
  result += address.apartment ? address.apartment + ", " : "";
  result += address.entrance ? address.entrance + ", " : "";
  result += address.building ? address.building + ", " : "";
  result += address.street ? address.street + ", " : "";
  result += address.number ? address.number + ", " : "";
  result += address.city ? address.city + ", " : "";
  result += address.sector ? address.sector + ", " : "";
  result += address.commune ? address.commune + ", " : "";
  result += address.country ? address.country + ", " : "";
  result += address.state ? address.state : "";

  return result;
}

function buildFreeFormAddress(address) {
  if (!address) {
    return "";
  }

  let result;
  if (address.lines) {
    result = address.lines.slice(0, 2).filter(Boolean).join(", ");
  } else {
    result = "";
  }

  if (result !== "") {
    result += ", ";
  }

  result += address.city ? address.city + ", " : "";
  result += address.stateDescription ? address.stateDescription + ", " : address.state + ", ";
  result += address.zip ? address.zip + ", " : "";
  result += address.country ? (address.country === "CA" ? "Canada, " : address.country + ", ") : "";

  return result.slice(0, -2);
}

function capitalize(s) {
  if (!s) {
    return "";
  }

  return s
    .toLowerCase()
    .split(" ")
    .map(e => e.charAt(0).toUpperCase() + e.slice(1))
    .join(" ");
}

const beautifyURL = url => url.split("://")[1];

/**
 * Check if the given string is empty.
 * @param {String} str The string.
 * @return {Boolean} True if the provided string is empty.
 */
const isEmpty = str => {
  return !str || 0 === str.length;
};

const getISODate = date => {
  var theDate = new Date(date);
  return (
    theDate.getFullYear() +
    "-" +
    new String(theDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    new String(theDate.getDate()).padStart(2, "0")
  );
};

const getISODateTime = date => {
  var theDate = new Date(date);
  return (
    theDate.getFullYear() +
    "-" +
    new String(theDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    new String(theDate.getDate()).padStart(2, "0") +
    "T" +
    new String(theDate.getHours()).padStart(2, "0") +
    ":" +
    new String(theDate.getMinutes()).padStart(2, "0") +
    ":" +
    new String(theDate.getSeconds()).padStart(2, "0") +
    "." +
    new String(theDate.getMilliseconds()).padStart(3, "0") +
    "Z"
  );
};

const getISODateTimeEndOfDay = date => {
  var theDate = new Date(date);

  return (
    theDate.getFullYear() +
    "-" +
    new String(theDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    new String(theDate.getDate()).padStart(2, "0") +
    "T23:59:59.000Z"
  );
};

const getISODateTimeStartOfDay = date => {
  var theDate = new Date(date);

  return (
    theDate.getFullYear() +
    "-" +
    ("" + (theDate.getMonth() + 1)).padStart(2, "0") +
    "-" +
    ("" + theDate.getDate()).padStart(2, "0") +
    "T00:00:00.000Z"
  );
};

const getRandomIndexes = (len, num) => {
  var arr = Array.from(Array(len).keys());
  var ret = [];
  var rnd;
  while (ret.length < num) {
    rnd = Math.floor(Math.random() * arr.length);
    ret.push(arr[rnd]);
    arr[rnd] = "";
    arr = arr.filter(a => {
      return a !== "";
    });
  }
  return ret;
};

const getImageDimensions = src => {
  const img = new Image();
  img.src = src;
  return { width: img.width, height: img.height };
};

const parsePackageDescription = text => {
  const firstDash = text.indexOf("-");
  const lastDash = text.lastIndexOf("-");

  if (firstDash === -1 || firstDash === lastDash) return text;
  return text.substring(firstDash + 1, lastDash);
};

const parseInvoiceDescription = text => {
  var temp = text;
  if (text) {
    temp = text.split(";").map(element => element.trim());
  }

  return temp;
};

const addDays = (date, numOfDays) =>
  new Date(
    date.getTime() +
      numOfDays * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISEC_IN_SECOND
  );

const isMoney = unitDescription => {
  return unitDescription === "$" || unitDescription === "Money";
};

const isData = unitDescription => {
  return ["MB", "GB"].includes(unitDescription);
};

const isMobileView = () => {
  const browserFontSize = parseFloat(
    getComputedStyle(document.body, null).getPropertyValue("font-size")
  );
  return window.innerWidth <= 40 * browserFontSize;
};

const getBreakpoints = () => {
  const browserFontSize = parseFloat(
    getComputedStyle(document.body, null).getPropertyValue("font-size")
  );
  let itemsPerRow = 4;
  if (window.innerWidth <= 96.1 * browserFontSize) {
    itemsPerRow = 3;
  }
  if (window.innerWidth <= 64.1 * browserFontSize) {
    itemsPerRow = 2;
  }
  if (window.innerWidth <= 40 * browserFontSize) {
    itemsPerRow = 1;
  }

  return itemsPerRow;
};

const getCardsPerRow = (pageRef, cardRef, cardWidth) => {
  let itemsPerPage;
  if (pageRef?.current && cardRef?.current) {
    itemsPerPage = Math.floor(
      pageRef?.current?.getBoundingClientRect().width /
        cardRef?.current?.getBoundingClientRect().width
    );
  } else if (pageRef?.current && !cardRef?.current) {
    itemsPerPage = Math.floor(pageRef?.current?.getBoundingClientRect().width / cardWidth);
  } else {
    itemsPerPage = getBreakpoints() - 1;
  }

  return itemsPerPage || 1;
};

export {
  getISODate,
  getISODateTime,
  getISODateTimeStartOfDay,
  getISODateTimeEndOfDay,
  maskString,
  getDaysUntil,
  getDaysUntilFromDate,
  getRemainingDaysPIA,
  getDaysUntilNextBP,
  buildAddress,
  getFullName,
  capitalize,
  beautifyURL,
  isEmpty,
  getDateWithoutTimezone,
  getRandomIndexes,
  getImageDimensions,
  parsePackageDescription,
  parseInvoiceDescription,
  addDays,
  isMoney,
  isData,
  isMobileView,
  getBreakpoints,
  getCardsPerRow,
};
