import React from "react";
import ChangeUsername from "./ChangeUsername";
import ChangePassword from "./ChangePassword";
import LogOut from "./LogOut";
import Phone from "./Phone";
import Edit from "./Edit";
import NotificationBell from "./NotificationBell";
import ViewUsageDetails from "./ViewUsageDetails";
import NeedHelp from "./NeedHelp";
import Call from "./Call";
import Chat from "./Chat";
import Search from "./Search";
import Back from "./Back";
import Remove from "./CogecoRemove";
import ViewDeviceDetails from "./ViewDeviceDetails";

import Close from "../base/Close";
import AccountDetails from "./AccountDetails";
import PaymentInformation from "./PaymentInformation";
import ViewSummary from "./ViewSummary";
import MyAccount from "./MyAccount";
import Home from "./Home";
import MyServices from "./MyServices";
import MyInvoices from "./MyInvoices";
import MyPayments from "./MyPayments";
import Language from "./Language";
import User from "./User";
import RequestAssist from "./RequestAssist";
import ServiceFeatures from "./ServiceFeatures";
import Lock from "../base/Lock";
import DownArrow from "../base/DownArrow";
import UpArrow from "../base/UpArrow";
import { useThemeUI } from "@theme-ui/core";
import InfinityIcon from "../base/Infinity";
import ModalClose from "../base/ModalClose";
import Next from "../base/Next";
import Prev from "../base/Prev";

const CogecoIcon = ({ name, ...props }) => {
  const { theme } = useThemeUI();
  switch (name) {
    case "home":
      return <Home />;
    case "myServices":
      return <MyServices />;
    case "myAccount":
      return <MyAccount />;
    case "accountDetails":
      return <AccountDetails />;
    case "myInvoices":
      return <MyInvoices />;
    case "myPayments":
      return <MyPayments />;
    case "language":
      return <Language {...props} />;
    case "paymentInformation":
      return <PaymentInformation />;
    case "user":
      return <User />;
    case "requestAssist":
      return <RequestAssist />;
    case "changeUsername":
      return <ChangeUsername {...props} />;
    case "change-password":
      return <ChangePassword {...props} />;
    case "logout":
      return <LogOut {...props} />;
    case "phone":
      return <Phone {...props} />;
    case "viewDeviceDetails":
      return <ViewDeviceDetails />;
    case "edit":
      return <Edit />;
    case "serviceFeatures":
      return <ServiceFeatures />;
    case "notificationBell":
      return <NotificationBell />;
    case "viewUsageDetails":
      return <ViewUsageDetails {...props} />;
    case "chat":
      return <Chat {...props} />;
    case "close":
      return <Close />;
    case "question-mark":
      return <NeedHelp />;
    case "chatPhone":
      return <Call />;
    case "search":
      return <Search />;
    case "back-mobile":
      return <Back {...props} />;
    case "remove":
      return <Remove />;
    case "viewEye":
      return <ViewSummary />;
    case "lock":
      return <Lock {...props} />;
    case "downArrow":
      return <DownArrow stroke={theme.colors.sunshine} {...props} />;
    case "upArrow":
      return <UpArrow stroke={theme.colors.sunshine} {...props} />;
    case "infinity":
      return <InfinityIcon stroke={theme.colors.primary} {...props} />;
    case "modalClose":
      return (
        <ModalClose fill={theme.colors.modalCloseBg} fill2={theme.colors.primary} {...props} />
      );
    case "next":
      return <Next stroke={theme.colors.primary} {...props} />;
    case "prev":
      return <Prev stroke={theme.colors.primary} {...props} />;

    default:
      return null;
  }
};

export default CogecoIcon;
