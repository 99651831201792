import React, { useCallback, useState, useEffect, useRef, forwardRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Text, Progress, Button } from "@theme-ui/components";

import { isData, isMoney } from "../../common/Utilities";
import OutsideClickHandler from "../OutsideClickHandler";
import MemebersCardSkeleton from "./MembersCardSkeleton";
import GetErrorDescription from "../GetErrorDescription";
import { StyledModalMessage } from "../modals";
import { SelfcareIcon } from "../icons";
import UsageRow from "./UsageRow";

import { setSharedMembersDisplay } from "../../redux/slices/UserSlice";
import {
  setSelectedSharedPlan,
  getAccountsSharedUsageDetails,
  setPlanError,
} from "../../redux/slices/PlanSlice";
import { SelfcareAmount } from "../base";
import FormatPhone from "../FormatPhone";

const computeUnits = (baseUnitRate, unitDescription, totalQuantity, usedQuantity) => {
  let result = {
    total: null,
    used: null,
    unit: null,
  };

  result.total = totalQuantity === -1 ? totalQuantity : totalQuantity / baseUnitRate;
  result.used = usedQuantity / baseUnitRate;
  result.unit = unitDescription;
  return result;
};

const formatPercentage = quantity => {
  return Number.parseInt(quantity.toFixed(1).replace(/[.,]0$/, ""));
};

const FUBCard = forwardRef(({ fub, showMembers = true, ...props }, ref) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cardRef = useRef();

  const [isShowOverlay, setShowOverlay] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rightPosition, setRightPosition] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const { master } = useSelector(state => state.user);
  const {
    accountsSharedUsageDetails,
    shared_usage_details_status,
    planError,
    hiddenBdus,
    alwaysDisplayOverageBdus,
  } = useSelector(state => state.plan);
  const fubUnits = computeUnits(fub.baseUnitRate, fub.actualUnitDescription, fub.total, fub.used);
  const browserFontSize = parseFloat(
    getComputedStyle(document.body, null).getPropertyValue("font-size")
  );
  const isDesktop = window.innerWidth > 96.1 * browserFontSize;

  const showMyUsage = !showMembers;

  const shouldHideBeneficiaryUnit = fub => {
    return fub.bduType && hiddenBdus.includes(fub.bduType);
  };

  const shouldIncludeBeneficiaryUnit = fub => {
    return fub.bduType && alwaysDisplayOverageBdus.includes(fub.bduType);
  };

  const setOverlayPosition = () => {
    if (cardRef?.current) {
      const position = cardRef.current.getBoundingClientRect();
      setRightPosition(position.width);
    }
  };

  const handleResize = useCallback(
    event => {
      if (event.key === "Escape") {
        setShowOverlay(false);
      }
      setWindowSize(window.innerWidth, window.innerHeight);
    },
    [setShowOverlay]
  );
  const getViewMessage = () => {
    if (!isShowOverlay) {
      return intl.formatMessage({ id: "lbl.view_members" });
    }

    if (isShowOverlay) {
      return intl.formatMessage({ id: "lbl.hide_members" });
    }
  };

  const getMembersList = () => {
    if (!isShowOverlay) {
      dispatch(
        getAccountsSharedUsageDetails({
          accountCode: master.accountCode,
          actualUnitCode: fub.actualUnitCode,
          bduType: fub.bduType,
          overage: fub.overage,
        })
      );
    }
  };

  useEffect(() => {
    if (isShowOverlay) {
      window.addEventListener("resize", handleResize);
    } else {
      window.removeEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [isShowOverlay, handleResize]);

  useEffect(() => {
    setOverlayPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  const openMembersTable = () => {
    if (!isDesktop) {
      dispatch(
        getAccountsSharedUsageDetails({
          accountCode: master.accountCode,
          actualUnitCode: fub.actualUnitCode,
          bduType: fub.bduType,
          overage: fub.overage,
        })
      );
    }

    window.scrollTo(0, 0);
    dispatch(setSelectedSharedPlan(fub));
    dispatch(setSharedMembersDisplay("ShowMembers"));
  };

  // hide zero usage buckets if:
  // - overage and not part of always displayed list
  // - non-overage and part of hidden
  if (
    fubUnits.used === 0 &&
    ((fub.overage && !shouldIncludeBeneficiaryUnit(fub)) || shouldHideBeneficiaryUnit(fub))
  ) {
    return null;
  }

  const showMembersOverlay = () => {
    setOverlayPosition();
    getMembersList();
    setShowOverlay(!isShowOverlay);
  };

  const FUBCardOverlay = () => {
    const maxPerCard = 5;
    const sharedUsage = [...accountsSharedUsageDetails];

    useEffect(() => {
      if (shared_usage_details_status === "failed") {
        //shows error msg on refresh because plans is still fetching
        setIsError(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowOverlay, shared_usage_details_status]);

    const closeError = () => {
      dispatch(setPlanError(null));
      setIsError(false);
    };

    if (shared_usage_details_status === "loading") {
      return (
        <Flex pr="0.5rem">
          <Flex
            ml="-0.5rem"
            bg="contentBg"
            sx={{
              position: "absolute",
              left: rightPosition,
              boxShadow: "light",
              borderRadius: "card",
              whiteSpace: "nowrap",
              border: "solid 1px",
              width: "29.88125rem",
              height: "15.625rem",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              alignItems: "center",
              borderColor: "border",
              zIndex: "1",
            }}>
            <Flex
              bg="contentBg"
              sx={{
                position: "relative",
                right: "1.08rem",
                height: "2.05rem",
                width: "2.05rem",
                zIndex: "2",
                border: "1px solid",
                borderRightColor: "border",
                borderTopColor: "border",
                transform: "rotate(45deg)",
                borderLeft: "solid transparent",
                borderBottom: "solid transparent",
              }}
            />
            <Flex
              py="medium"
              sx={{
                height: "100%",
                width: "28.64375rem",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Flex
                mt="tiny"
                sx={{
                  width: "100%",
                  flexDirection: "column",
                }}></Flex>
              <MemebersCardSkeleton />
              <Text variant="link" sx={{ justifyContent: "flex-end" }}>
                <FormattedMessage id="lbl.view_all_members" /> »
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex pr="0.5rem">
        <Flex
          ml="-0.5rem"
          bg="contentBg"
          sx={{
            position: "absolute",
            left: rightPosition,
            boxShadow: "light",
            borderRadius: "card",
            whiteSpace: "nowrap",
            border: "solid 1px",
            width: "29.88125rem",
            height: "15.625rem",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            alignItems: "center",
            borderColor: "border",
            zIndex: "1",
          }}>
          <Flex
            bg="contentBg"
            sx={{
              position: "relative",
              right: "1.08rem",
              height: "2.05rem",
              width: "2.05rem",
              zIndex: "2",
              border: "1px solid",
              borderRightColor: "border",
              borderTopColor: "border",
              transform: "rotate(45deg)",
              borderLeft: "solid transparent",
              borderBottom: "solid transparent",
            }}
          />
          <Flex
            py="medium"
            sx={{
              height: "100%",
              width: "28.64375rem",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
            <Flex mt="tiny" sx={{ width: "100%", flexDirection: "column" }}>
              {sharedUsage &&
                sharedUsage
                  .sort((a, b) => b.used - a.used)
                  .slice(0, maxPerCard)
                  .map((member, index) => {
                    return (
                      <UsageRow
                        key={index}
                        ani={<FormatPhone number={member.phoneNumber} />}
                        usage={member.used / fub.baseUnitRate}
                        unit={fubUnits.unit}
                        total={fub.total > 1 ? fubUnits.total : fub.total}
                        formatPercentage={formatPercentage}
                      />
                    );
                  })}
            </Flex>

            <Text
              variant="link"
              onClick={sharedUsage ? openMembersTable : null}
              sx={{ justifyContent: "flex-end" }}>
              <FormattedMessage id="lbl.view_all_members" /> »
            </Text>
          </Flex>
        </Flex>

        <StyledModalMessage
          isOpen={isError && planError}
          message={planError && <GetErrorDescription error={planError} />}
          onRequestClose={closeError}
          type="error">
          <Button onClick={closeError}>
            <FormattedMessage id="lbl.Try_Again" />
          </Button>
        </StyledModalMessage>
      </Flex>
    );
  };

  const Consumed = ({ isTotal, consumedAmount, consumedUnit = fubUnits.unit, ...props }) => {
    if (isMoney(consumedUnit))
      return (
        <Text mx="tiny" sx={{ fontWeight: "semiBold", ...props.sx }}>
          <SelfcareAmount amount={consumedAmount} />
        </Text>
      );
    return (
      <Text
        mx="tiny"
        sx={{
          fontWeight: "semiBold",
          ...props.sx,
        }}
        {...props}>
        <FormattedMessage
          id={isData(consumedUnit) ? "lbl.consumedDecimal" : "lbl.consumed"}
          values={{
            amount:
              consumedAmount < 0 || (consumedAmount === 0 && isTotal) ? (
                <SelfcareIcon name="infinity" />
              ) : (
                consumedAmount
              ),
            unit: consumedUnit === "$" ? "Money" : consumedUnit,
          }}
        />
      </Text>
    );
  };

  return (
    <OutsideClickHandler
      onClickOutside={() => {
        setShowOverlay(false);
      }}>
      <Flex ref={ref} sx={{ position: "relative" }}>
        <Box
          {...props}
          ref={cardRef}
          bg="contentBg"
          sx={{
            height: ["13rem", "15.625rem", "15.625rem"],
            width: "100%",
            boxShadow: "light",
            borderRadius: "card",
            border: "1px solid",
            borderColor: "border",
            borderTopRightRadius: isShowOverlay ? 0 : "card",
            borderBottomRightRadius: isShowOverlay ? 0 : "card",
            borderRight: isShowOverlay ? "transparent" : "card",
            ...props.sx,
          }}>
          <Flex
            mx="large"
            my="medium"
            sx={{
              flexDirection: "column",
            }}>
            {showMembers && (
              <Flex sx={{ width: "100%", justifyContent: "flex-end" }}>
                <Text
                  variant="link"
                  sx={{ fontWeight: "semiBold" }}
                  onClick={!isDesktop ? openMembersTable : showMembersOverlay}>
                  {getViewMessage()}
                </Text>
              </Flex>
            )}

            <Flex
              mt={["small", "small", "default"]}
              mb={["tiny", "small", "small"]}
              sx={{
                height: "75px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}>
              <Flex sx={{ width: ["100%", "90%"], flexDirection: "column" }}>
                <Text
                  variant="headline"
                  onClick={showMembers ? showMembersOverlay : null}
                  sx={{ cursor: showMembers && "pointer" }}>
                  {intl.formatMessage({ id: "lbl.sharing_group" })}
                </Text>
                <Text sx={{ fontWeight: "semiBold" }}>
                  <FormattedMessage
                    id="lbl.bdu_type_description"
                    values={{
                      isOverage: fub.overage ? "yes" : "no",
                      bduDescription: fub.bduTypeDescription,
                    }}
                  />
                </Text>
              </Flex>
            </Flex>

            {fub.total > 0 ? (
              <Flex
                mb="tiny"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}>
                <Flex
                  p="tiny"
                  sx={{
                    height: "1.375rem",
                    width: "80%",
                    borderRadius: 10,
                    alignItems: "center",
                  }}>
                  <Progress
                    max={1}
                    variant="styles.progress"
                    value={1 - fubUnits.used / fubUnits.total}
                  />
                </Flex>

                <Text
                  ml="tiny"
                  sx={{
                    width: "2.9rem",
                    textAlign: "right",
                  }}>
                  {formatPercentage((fubUnits.used / fubUnits.total) * 100)}%
                </Text>
              </Flex>
            ) : (
              <Flex mb="tiny">
                <Flex
                  p="tiny"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    background: "progressBg",
                    width: "100%",
                    borderRadius: 10,
                    height: "1.375rem",
                  }}>
                  <SelfcareIcon name="infinity" />
                </Flex>
              </Flex>
            )}

            <Text mb={showMyUsage ? "small" : "default"} sx={{ textAlign: "center" }}>
              <Consumed consumedAmount={fubUnits.used} />
              {fubUnits.total > 1 ? (
                <>
                  <FormattedMessage id="lbl.out_of" />
                  <Consumed isTotal consumedAmount={fubUnits.total} />
                </>
              ) : (
                intl.formatMessage({ id: "lbl.used" }).toLocaleLowerCase()
              )}
            </Text>

            {showMyUsage && (
              <Flex mb="small" sx={{ justifyContent: "center" }}>
                <Text color="primary">
                  <FormattedMessage id="lbl.my_consump" />
                </Text>
                <Consumed color="primary" ml="tiny" consumedAmount={fubUnits.used} />
                {fubUnits.total > 1 && (
                  <Text color="primary" ml="tiny">
                    ({formatPercentage((fubUnits.used / fubUnits.total) * 100) + "%"})
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        </Box>

        {isShowOverlay && <FUBCardOverlay />}
      </Flex>
    </OutsideClickHandler>
  );
});

FUBCard.displayName = "FUBCard";
export default FUBCard;
