import logo from "../resources/images/logo.svg";
import logo_white from "../resources/images/logo_white.svg";
import el_logo from "../resources/images/eastlink-logo-white.svg";
import Theme from "./themes";
import docomo_logo from "../resources/images/logos/docomopacific.svg";
import docomo_logo_white from "../resources/images/logos/docomopacific_white.svg";
import cogeco_blue from "../resources/images/logo_cogeco.svg";
import messages_en from "../resources/translations/en.json";
import messages_fr from "../resources/translations/fr.json";
import messages_en_cogeco from "../resources/translations/en_cogeco.json";
import messages_fr_cogeco from "../resources/translations/fr_cogeco.json";

export const Providers = {
  base: {
    theme: Theme,
    logo: logo_white,
    logoAlt: logo,
    bodyFont: "'Work Sans', sans-serif",
    language: {
      MESSAGES: {
        "en-CA": messages_en,
        "fr-CA": messages_fr,
      },

      SUPPORTED_LOCALES: {
        "en-CA": "English",
        "fr-CA": "Français",
      },

      LANGUAGE_ISOCODE: {
        English: "en-CA",
        French: "fr-CA",
      },

      INITIAL_LOCALE: "en-CA",
    },
    config: {
      darkMode: false,
      phoneNumber: "+972-4-993-6666",
      website: "https://www.mindcti.com",
      terms_and_conditions: "https://www.mindcti.com/terms-and-conditions/",
      limitAccessByProvider: false,
      themeSwitch: true,
      updatePackage: true,
      eCheckVisible: true,
      includeNBCallsVisible: true,
      identityTypeAndNoVisible: true,
      receiveMarketingDetailsVisible: true,
      viewPostpaidUnbilledSummary: true,
      mobilePhoneNumberVisible: true,
      phoneNumberPattern: {
        "en-CA": {
          searchPattern: /(?<int>\d)?(?<area>\d{3})(?<office>\d{3})(?<line>\d{4})/,
          replacePattern: (match, int, area, office, line) =>
            (int ? int + "-" : "") + area + "-" + office + "-" + line,
        },
        "fr-CA": {
          searchPattern: /(?<int>\d)?(?<area>\d{3})(?<office>\d{3})(?<line>\d{4})/,
          replacePattern: (match, int, area, office, line) =>
            (int ? int + "-" : "") + area + "-" + office + "-" + line,
        },
      },
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  el: {
    theme: Theme,
    logo: el_logo,
    logoAlt: el_logo,
    bodyFont: "'Work Sans', sans-serif",
    language: {
      MESSAGES: {
        "en-CA": messages_en,
        "fr-CA": messages_fr,
      },

      SUPPORTED_LOCALES: {
        "en-CA": "English",
        "fr-CA": "Français",
      },

      LANGUAGE_ISOCODE: {
        English: "en-CA",
        French: "fr-CA",
      },

      INITIAL_LOCALE: "en-CA",
    },
    config: {
      darkMode: window._env_ && window._env_.darkMode,
      copyright: "© Eastlink",

      website: "https://www.eastlink.ca",
      terms_and_conditions: "https://www.eastlink.ca/about/terms-and-conditions",
      change_bill_type_link: "https://my.eastlink.ca/customer-support/billing/faq?version=2",
      how_to_read_your_bill:
        "https://my.eastlink.ca/customer-support/billing/understand-your-bill/version/2",
      phoneNumber: { Residential: "1-888-345-1111", Commercial: "1-877-813-1727" },
      phoneNumberMask: {
        mask: ["999-999-9999", "1-999-999-9999"],
        placeholder: "___-___-____",
        pattern: /^[1]?[0-9]{3}[0-9]{3}[0-9]{4}$/,
      },
      phoneNumberPattern: {
        "en-CA": {
          searchPattern: /(?<int>\d)?(?<area>\d{3})(?<office>\d{3})(?<line>\d{4})/,
          replacePattern: (match, int, area, office, line) =>
            (int ? int + "-" : "") + area + "-" + office + "-" + line,
        },
        "fr-CA": {
          searchPattern: /(?<int>\d)?(?<area>\d{3})(?<office>\d{3})(?<line>\d{4})/,
          replacePattern: (match, int, area, office, line) =>
            (int ? int + "-" : "") + area + "-" + office + "-" + line,
        },
      },
      limitAccessByProvider: true, // forgot password
      themeSwitch: true,
      updatePackage: false,
      includeNBCallsVisible: false, // usage details
      identityTypeAndNoVisible: false, // contact details
      receiveMarketingDetailsVisible: false, // cpni details
      mobilePhoneNumberVisible: false, // contact details
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  docomo: {
    theme: {
      ...Theme,
      colors: {
        ...Theme.colors,
        primary: "rgba(74, 77, 78, 1)",
        headerBg: "white",
        navItemBg: "rgba(74, 77, 78, 1)",
        secondary: "rgba(204, 0, 51, 1)",
        navMenuFocusedBg: "rgba(74, 77, 78, 1)",
        userMenu: "rgba(74, 77, 78, 1)",
        darkenHome: "rgba(216,67,91,1)",
      },
    },
    logo: docomo_logo,
    logoAlt: docomo_logo_white,
    bodyFont: "'Work Sans', sans-serif",
    language: {
      MESSAGES: {
        "en-CA": messages_en,
        "fr-CA": messages_fr,
      },

      SUPPORTED_LOCALES: {
        "en-CA": "English",
        "fr-CA": "Français",
      },

      LANGUAGE_ISOCODE: {
        English: "en-CA",
        French: "fr-CA",
      },

      INITIAL_LOCALE: "en-CA",
    },
    config: {
      darkMode: false,
      copyright: "© DOCOMO PACIFIC Guam",
      phoneNumber: "Guam: +1.671.688.2273\nCNMI: +1.670.488.2273",
      website: "https://www.docomopacific.com/",
      terms_and_conditions: "https://www.docomopacific.com/about-us/terms-and-conditions",
      limitAccessByProvider: true, // forgot password
      themeSwitch: true,
      updatePackage: false,
      includeNBCallsVisible: false, // usage details
      identityTypeAndNoVisible: false, // contact details
      receiveMarketingDetailsVisible: false, // cpni details
      mobilePhoneNumberVisible: false, // contact details
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
  cogeco: {
    theme: {
      ...Theme,
      colors: {
        ...Theme.colors,
        primary: "#001E62",
        secondary: "#FF6D70",
        headerBg: "#001E62",
        navItemStroke: "#FFF",
        sunshine: "#FF6D72",
        border: "#E6E7EB",
        contentBg: "#FFF",
        navMenuBg: "#F2F3F7",
        highlighted: "#00A7B5",
        highlight: "#FFF", // override default
        error: "#be1e2d",
        textError: "#be1e2d",
        textLight: "#FFF",
        disabled: "#E6E7EB",
        tableDate: "#001E62",
        disabledColor: "#8c8c8c",
        progressBg: "#d3d3d3",
        alert: "#721c24",
        alertBg: "#f8d7da",
        activeStatus: "#cbffe5",
      },
      fonts: {
        body: "'Quicksand', sans-serif",
        heading: "'Quicksand', sans-serif",
      },
      buttons: {
        primary: {
          position: "relative",
          zIndex: 1,
          background: "linear-gradient(-135deg, #FF6D70 0%, #001E62 75%, #001E62 100%)",
          borderRadius: "circle",
          width: "8rem",
          boxShadow: "default",
          fontFamily: "body",
          padding: "small",
          fontSize: "default",
          fontWeight: "600",
          lineHeight: "1.5rem",
          px: "1rem",
          ":before": {
            position: "absolute",
            content: "''",
            inset: 0,
            zIndex: -1,
            opacity: 0,
            background: "linear-gradient(135deg, #FF6D70 0%, #001E62 75%, #001E62 100%)",
            transition: "opacity 0.5s linear",
            borderRadius: "circle",
          },
          ":hover::before": {
            opacity: 1,
          },
        },
        secondary: {
          position: "relative",
          background: "#FFF",
          border: "0.125rem solid",
          bodrderColor: "primary",
          color: "primary",
          borderRadius: "circle",
          width: "8rem",
          boxShadow: "default",
          fontFamily: "body",
          padding: "small",
          fontSize: "default",
          fontWeight: "600",
          lineHeight: "1.5rem",
          px: "1rem",
          ":hover": {
            borderColor: "secondary",
          },
        },
        tertiaryNoWidth: {
          outline: "none !important",
          textAlign: "center",
          cursor: "pointer",
          letterSpacing: "0.36px",
          fontFamily: "body",
          fontWeight: "medium",
          fontSize: 3,
          borderRadius: 0,
          color: "textLight",
          bg: "primary",
          height: ["3rem", "2.75rem"],
          "&:hover": {
            opacity: "90%",
          },
          "&:disabled": {
            cursor: "default",
            "&:hover": {
              opacity: "100%",
            },
            bg: "inactive",
            color: "note",
          },
        },
        tertiaryWhiteNoWidth: {
          outline: "none !important",
          textAlign: "center",
          cursor: "pointer",
          fontFamily: "body",
          fontWeight: "medium",
          fontSize: 3,
          color: "primary",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "primary",
          borderRadius: 0,
          bg: "contentBg",
          height: ["3rem", "2.75rem"],
          "&:hover": {
            opacity: "90%",
          },
          "&:disabled": {
            cursor: "default",
            "&:hover": {
              opacity: "100%",
            },
            bg: "inactive",
            color: "note",
          },
        },
        paymentAction: {
          alignItems: "center",
          justifyContent: "center",
          alignSelf: ["center", "center", "initial", "initial"],
          bg: "navMenuBg",
          borderRadius: 25,
          boxShadow: "default",
          color: "primary",
          cursor: "pointer",
          fontFamily: "body",
          p: "medium",
          width: ["100%", "22.5rem", "22.5rem"],
          ":hover": {
            cursor: "pointer",
            opacity: 0.9,
          },
          ":disabled": {
            cursor: "default",
            bg: "disabled",
            opacity: "75%",
          },
        },
      },
      radii: {
        default: 0,
        card: 20,
        circle: 99999,
        spinner: 12,
      },
      layout: {
        card: {
          flexDirection: "column",
          height: "15.625rem",
          width: ["100%", "100%", "22rem", "24.25rem"],
          border: "solid 1px",
          borderRadius: "card",
          borderColor: "border",
          padding: ["default", "default", "default", "largeish"],
        },
        section: {
          flexDirection: "column",
          width: "100%",
          maxWidth: ["100%", "100%", "72rem", "78.75rem"],
          border: "1px solid",
          borderColor: "border",
          padding: ["default", "large"],
          borderRadius: "card",
          boxShadow: "default",
        },
        serviceCard: {
          border: "1px solid",
          borderColor: "border",
          bg: "contentBg",
          px: ["small", "default", "large", "large"],
          py: ["default", "1.75rem"],
          boxShadow: "light",
          borderRadius: "card",
          my: "large",
        },
      },
      text: {
        ...Theme.text,
        headline: {
          color: "primary",
          fontWeight: "semiBold",
          fontSize: [6, 6, 8, 8],
        },
        heading: {
          fontFamily: "body",
          color: "primary",
          fontWeight: "bold",
          fontSize: [6, 6, 8, 8],
          textAlign: ["center", "left", "left"],
          my: ["default", "default", "medium"],
          mb: ["small", "default", "medium"],
        },
      },
      forms: {
        input: {
          fontFamily: "body",
          fontWeight: "400",
          borderColor: "#898d95",
          borderRadius: "0.5rem",
          height: "2.75rem",
          p: "default",
          width: "100%",
          ":placeholder": {
            opacity: 0.42,
          },
          ":focus": {
            outline: "solid",
            outlineColor: "highlighted",
            outlineWidth: "2px",
            outlineOffset: "1px",
          },
          ":hover": { borderColor: "highlighted" },
          ":disabled": {
            backgroundColor: "disabled",
            borderColor: "disabled",
          },
          "::-ms-clear": {
            display: "none",
          },
          "::-ms-reveal": {
            display: "none",
          },
        },
        inputError: {
          fontFamily: "body",
          fontWeight: "400",
          borderColor: "error",
          borderRadius: "0.5rem",
          height: "2.75rem",
          p: "default",
          width: "100%",
          ":placeholder": {
            opacity: 0.42,
          },
          ":focus": {
            outline: "solid",
            outlineColor: "error",
            outlineWidth: "2px",
            outlineOffset: "1px",
          },
          ":hover": { borderColor: "highlighted" },
          ":disabled": {
            backgroundColor: "secondary",
            borderColor: "secondary",
          },
          "::-ms-clear": {
            display: "none",
          },
          "::-ms-reveal": {
            display: "none",
          },
        },
        select: {
          p: "small",
          appearance: "none",
          fontFamily: "body",
          fontSize: 2,
          border: 1,
          borderRadius: "0.5rem",
          color: "input",
          mb: ["small", 0, 0],
          bg: "transparent",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          justifyContent: "flex-end",
          height: "2.75rem",
          borderColor: "#898d95",
          ":hover": {
            borderColor: "highlighted",
          },
          ":disabled": {
            backgroundColor: "disabled",
            borderColor: "disabled",
          },
          "::-ms-clear": {
            display: "none",
          },
          "::-ms-reveal": {
            display: "none",
          },
          "&:focus": {
            outline: "solid",
            outlineColor: "highlighted",
            outlineWidth: "2px",
            outlineOffset: "1px",
          },
        },
        selectError: {
          bg: "transparent",
          height: "2.75rem",
          borderColor: "error",
          borderRadius: "card",
          p: "small",
          color: "error",
          fontFamily: "body",
          fontSize: 2,
          "::-ms-clear": {
            display: "none",
          },
          "::-ms-reveal": {
            display: "none",
          },
          width: "100%",
          "&:focus": {
            outline: "solid",
            borderColor: "error",
            outlineColor: "error",
            outlineWidth: "1px",
          },
        },
      },
      links: {
        ...Theme.links,
        nav: {
          color: "primary",
          fontSize: "default",
          fontWeight: "semiBold",
          lineHeight: 1.5,
          textDecoration: "none",
          ":hover": { textDecoration: "underline", textDecorationColor: "secondary" },
        },
        link: {
          textDecoration: "none",
          color: "primary",
          ":hover": {
            textDecoration: "underline",
          },
        },
      },
      styles: {
        progress: {
          background: "linear-gradient(135deg, #FF6D70 0%, #001E62 75%, #001E62 100%)",
          color: "progressBg",
          height: "1.3rem",
          width: "100%",
          transform: "scaleX(-1)",
        },
      },
    },
    bodyFont: "'Quicksand', sans-serif",
    logo: cogeco_blue,
    logoAlt: cogeco_blue,
    language: {
      MESSAGES: {
        "en-CA": messages_en_cogeco,
        "fr-CA": messages_fr_cogeco,
      },

      SUPPORTED_LOCALES: {
        "en-CA": "English",
        "fr-CA": "Français",
      },

      LANGUAGE_ISOCODE: {
        English: "en-CA",
        French: "fr-CA",
      },

      INITIAL_LOCALE: "en-CA",
    },
    config: {
      darkMode: window._env_ && window._env_.darkMode,
      copyright: "© Cogeco Connexion",
      website: "https://www.cogeco.ca/en",
      terms_and_conditions: "https://www.eastlink.ca/about/terms-and-conditions",
      phoneNumber: "1-877-717-6480",
      phoneNumberMask: {
        mask: ["999-999-9999", "1-999-999-9999"],
        placeholder: "___-___-____",
        pattern: /^[1]?[0-9]{3}[0-9]{3}[0-9]{4}$/,
      },
      phoneNumberPattern: {
        "en-CA": {
          searchPattern: /(?<int>\d)?(?<area>\d{3})(?<office>\d{3})(?<line>\d{4})/,
          replacePattern: (match, int, area, office, line) =>
            (int ? int + "-" : "") + area + "-" + office + "-" + line,
        },
        "fr-CA": {
          searchPattern: /(?<int>\d)?(?<area>\d{3})(?<office>\d{3})(?<line>\d{4})/,
          replacePattern: (match, int, area, office, line) =>
            (int ? int + "-" : "") + area + "-" + office + "-" + line,
        },
      },
      change_bill_type_link: "https://my.eastlink.ca/customer-support/billing/faq?version=2",
      how_to_read_your_bill:
        "https://my.eastlink.ca/customer-support/billing/understand-your-bill/version/2",

      limitAccessByProvider: true, // forgot password
      themeSwitch: true,
      updatePackage: false,
      includeNBCallsVisible: false, // usage details
      identityTypeAndNoVisible: false, // contact details
      receiveMarketingDetailsVisible: false, // cpni details
      mobilePhoneNumberVisible: false, // contact details
      troubleTicket: {
        assistance: {
          area: "SELFCARE_ASSIST",
          role: "BILLING",
          type: "SELF_AGENT",
        },
        service: {
          area: "SELFCARE_SRVC",
          role: "BILLING",
          type: "SELF_SGENT",
        },
        upgrade: {
          area: "SELFCARE_UPGRD",
          role: "BILLING",
          type: "SELF_UGENT",
        },
      },
    },
  },
};

export const defaultProvider = (window._env_ && window._env_.PROVIDER) || "el";
