/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Flex, Heading, Link, Text } from "@theme-ui/components";

import GetErrorDescription from "../../components/GetErrorDescription";
import { StyledModalMessage } from "../../components/modals";
import { isMobileView } from "../../common/Utilities";
import SubmitButton from "../../components/SubmitButton";
import { updateEasyTravelService } from "./Utilities";
import { CRUISES_CODE } from "../../common/Constants";

import {
  getTravelPlan,
  setUpdateAccountProductStatus,
  updateAccountProduct,
} from "../../redux/slices/PlanSlice";
import FormatPhone from "../../components/FormatPhone";
import { usePhoneNumber } from "../../hooks/usePhoneNumber";

const noConfirmation = {
  isOpen: false,
  message: "",
  isError: false,
  easyTravel: false,
  isFirstConfirmation: false,
  isSecondConfirmation: false,
};

const EasyTravel = ({ selectedCountry }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isError, setIsError] = useState(false);
  const [showTc, setShowTc] = useState(false);
  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });
  const isMobile = isMobileView();

  const { account, master } = useSelector(state => state.user);
  const { travelPlan, planError, update_account_product_status } = useSelector(state => state.plan);
  const easyTravelLink = master?.udfCategory ? "link.commercial_travel_plans" : "link.travel_plans";
  const tcHeight = isMobile ? "100%" : "fit-content";
  const { travelPlanConfig } = useSelector(state => state.plan);
  const phoneNumber = usePhoneNumber(master?.udfCategory);

  useEffect(() => {
    if (
      update_account_product_status === "success" &&
      confirmation.easyTravel &&
      !confirmation.isSecondConfirmation
    ) {
      setConfirmation({
        isOpen: true,
        message: <FormattedMessage id={"lbl.activate_easyTravel_confirmation"} />,
        isSecondConfirmation: true,
      });
      dispatch(setUpdateAccountProductStatus(null));
    }
    if (update_account_product_status === "failed" && confirmation.easyTravel) {
      setIsError(true);
    }
  }, [
    confirmation.easyTravel,
    confirmation.isFirstConfirmation,
    confirmation.isSecondConfirmation,
    dispatch,
    travelPlan?.easyTravel,
    update_account_product_status,
  ]);

  const onCancel = () => {
    if (confirmation.isSecondConfirmation) {
      dispatch(getTravelPlan(account.accountCode));
    }
    setConfirmation(noConfirmation);
    setIsError(false);
  };

  const doAfterConfirmation = () => {
    if (confirmation.isFirstConfirmation) {
      setConfirmation({ isOpen: false, easyTravel: true });
    }
    if (!confirmation.isError && !confirmation.isSecondConfirmation) {
      dispatch(
        updateAccountProduct({
          accountCode: account.accountCode,
          product: updateEasyTravelService(account.productCode, travelPlan),
        })
      );
    }

    if (confirmation.isSecondConfirmation && !confirmation.isFirstConfirmation) {
      dispatch(getTravelPlan(account.accountCode));
      setConfirmation(noConfirmation);
    }
  };

  const onActivateEasyTravel = () => {
    if (travelPlan?.travelPack || travelPlan.withSpc) {
      setConfirmation({
        isOpen: true,
        isError: true,
        isFirstConfirmation: true,
        message: (
          <Text color="textDark">
            <FormattedMessage
              id="err.enable_easyTravel"
              values={{
                a: chunks => (
                  <Link variant="nav" target="_blank">
                    {chunks}
                  </Link>
                ),
                phoneNumber,
                category: master?.udfCategory,
              }}
            />
          </Text>
        ),
      });
    } else {
      setConfirmation({
        isOpen: true,
        message: null,
        isFirstConfirmation: true,
      });
    }
  };

  const closeError = () => {
    setIsError(false);
    dispatch(setUpdateAccountProductStatus(null));
    setConfirmation(noConfirmation);
  };

  const displayEasyTravelSection = () => {
    return travelPlanConfig.easyTravel.includes(selectedCountry);
  };

  if (!displayEasyTravelSection())
    return (
      <Flex
        mt={travelPlan?.withSpc ? "small" : "default"}
        sx={{ width: "100%", flexDirection: "column" }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.easyTravel" />
        </Heading>
        <Flex variant="layout.section">
          <Text color="textDark">
            <FormattedMessage
              id="lbl.easy_travel_not_available_for_country"
              values={{
                country:
                  selectedCountry === CRUISES_CODE
                    ? intl.formatMessage({ id: "lbl.cruises" })
                    : intl.formatDisplayName(selectedCountry, { type: "region" }),
              }}
            />
          </Text>
        </Flex>
      </Flex>
    );

  return (
    <Flex
      mt={travelPlan?.withSpc ? "small" : "default"}
      sx={{ width: "100%", flexDirection: "column" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.easyTravel" />
      </Heading>
      <Flex variant="layout.section">
        <Text color="textDark" sx={{ fontWeight: "semiBold" }}>
          <FormattedMessage id="lbl.save_money_and_travel" />
        </Text>

        <Text mt="default" variant="description">
          <FormattedMessage
            id="lbl.easyTravel_message"
            values={{
              p: chunks => <Text as="p">{chunks}</Text>,
              a: chunks => (
                <Link
                  variant="support"
                  href={intl.formatMessage({ id: easyTravelLink })}
                  target="_blank">
                  {chunks}
                </Link>
              ),
            }}
          />
        </Text>

        <Flex
          mt={["small", "default", "large"]}
          sx={{
            flexDirection: ["column", "row"],
            alignItems: "center",
            justifyContent: "space-between",
            gap: "default",
            pr: ["small", "small", "large"],
          }}>
          <Flex
            sx={{ flexDirection: ["column", "row", "row"], textAlign: ["center", "left", "left"] }}>
            <Text color="textDark" sx={{ fontWeight: "semiBold", fontSize: [3, 5, 5] }}>
              <FormattedMessage id="lbl.easyTravel_status" />
            </Text>

            <Text ml="small" sx={{ fontWeight: "semiBold", color: "primary", fontSize: [3, 5, 5] }}>
              <FormattedMessage
                id={travelPlan?.easyTravel ? "lbl.active_easyTravel" : "lbl.inactive_easyTravel"}
              />
            </Text>
          </Flex>
          {!travelPlan?.easyTravel && (
            <SubmitButton
              text={"lbl.activate"}
              isdisabled={travelPlan?.withSpc}
              onSubmit={onActivateEasyTravel}
              sx={{ alignSelf: ["center", "initial"] }}
            />
          )}
        </Flex>
      </Flex>

      {confirmation.isOpen && (
        <StyledModalMessage
          isOpen={confirmation.isOpen}
          message={confirmation.message}
          onRequestClose={onCancel}
          type="error">
          <Flex sx={{ flexDirection: "column", mt: ["default", 0] }}>
            {!confirmation.message && (
              <Text
                variant="inputlabel"
                sx={{
                  maxWidth: "55rem",
                  px: ["default", "default", "2rem"],
                  textAlign: "justify",
                  textAlignLast: "center",
                }}>
                <FormattedMessage
                  id="lbl.confirmation_enable_easyTravel"
                  values={{
                    a: chunks => (
                      <Link variant="nav" target="_blank" onClick={() => setShowTc(true)}>
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </Text>
            )}
            <Flex
              sx={{
                mx: "auto",
                mt: "large",
                flexDirection: ["column-reverse", "row", "row"],
                alignItems: "center",
              }}>
              {!confirmation.isError && !confirmation.isSecondConfirmation && (
                <Button variant="secondary" mt={["default", 0]} onClick={onCancel}>
                  <FormattedMessage id="lbl.cancel" />
                </Button>
              )}
              <Button
                ml={[0, "default"]}
                onClick={doAfterConfirmation}
                sx={{
                  whiteSpace: ["initial", "nowrap"],
                  width:
                    confirmation.isError || confirmation.isSecondConfirmation
                      ? "intial"
                      : "fit-content",
                  px: "default",
                }}>
                <FormattedMessage
                  id={
                    confirmation.isError || confirmation.isSecondConfirmation
                      ? "lbl.ok"
                      : travelPlan?.easyTravel
                      ? "lbl.remove_from"
                      : "lbl.add_now_to"
                  }
                  values={{
                    phoneNumber: <FormatPhone number={account?.phoneNumber} />,
                    isMobile,
                    br: <br />,
                  }}
                />
              </Button>
            </Flex>
          </Flex>
        </StyledModalMessage>
      )}

      <StyledModalMessage
        isOpen={showTc}
        message={<FormattedMessage id="lbl.conditions" />}
        onRequestClose={() => setShowTc(false)}
        height={tcHeight}>
        <Text
          variant="inputlabel"
          sx={{
            width: "55rem",
            px: ["small", "3rem"],
            textAlign: "justify",
            whiteSpace: "pre-line",
          }}>
          <FormattedMessage id="lbl.easyTravel_terms&conditions" />
        </Text>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isError && !!planError}
        message={planError && <GetErrorDescription error={planError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </Flex>
  );
};

export default EasyTravel;
