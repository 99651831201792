import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { buildAddress, getFullName } from "../../common/Utilities";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import SummaryRow from "../../components/SummaryRow/SummaryRow";
import SummaryLayout from "../../containers/SummaryLayout";
import { MESSAGES_CODES } from "../../common/Constants";
import { BrandingContext } from "../../contexts/BrandingContext";
import FormatPhone from "../FormatPhone";

const ContactDetails = ({ primaryContact, isLoading, fieldWidth = "13rem", onUpdateClick }) => {
  const intl = useIntl();
  const { locale } = useContext(SelfcareIntlContext);
  const { language } = useContext(BrandingContext);

  const { master } = useSelector(state => state.user);
  const handeUpdateClick = () => {
    onUpdateClick("contactDetails");
  };

  const width = locale == language.LANGUAGE_ISOCODE.French ? "17.25rem" : fieldWidth;

  if (isLoading) {
    return (
      <SummaryLayout
        title="lbl.contact_details"
        isLoading
        sx={{ width: "100%", maxWidth: "100%" }}
      />
    );
  }

  return (
    <SummaryLayout
      title="lbl.contact_details"
      showUpdate={String(master?.accountStatus).startsWith(MESSAGES_CODES.ACTIVE)}
      onUpdate={handeUpdateClick}
      showEdit={false}
      sx={{ width: "100%" }}>
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.name" })}
        value={getFullName(primaryContact)}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.address" })}
        value={buildAddress(primaryContact)}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.email" })}
        value={primaryContact.email}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.primary-number" })}
        value={<FormatPhone number={primaryContact.phone.home} />}
        fieldWidth={width}
      />
      <SummaryRow
        tooltip={intl.formatMessage({ id: "lbl.alternate-phone-number" })}
        value={<FormatPhone number={primaryContact.phone.business} />}
        fieldWidth={width}
      />
    </SummaryLayout>
  );
};

export default ContactDetails;
