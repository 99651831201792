import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Box, Heading, Text, Flex, Button } from "@theme-ui/components";

import {
  isEmpty,
  getDateWithoutTimezone,
  getISODateTimeStartOfDay,
  getISODateTimeEndOfDay,
  isMobileView,
} from "../../common/Utilities";
import LoadDocumentModal from "../../components/LoadDocumentModal";
import Layout from "../../containers/Layout";
import { getUsageDetails as getUsage, setUsageDetails } from "../../redux/slices/UserSlice";
import Datepicker from "../../components/Datepicker";
import GoBack from "../../components/GoBack";
import { FormErrorMessage } from "../../components/FormErrorMessage";
import SubHeader from "../../components/SubHeader";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { BrandingContext } from "../../contexts/BrandingContext";
import FormatPhone from "../../components/FormatPhone";

const UsageDetails = () => {
  const { accountCode } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { locale } = useContext(SelfcareIntlContext);
  const { language } = useContext(BrandingContext);

  const [dateFieldsError, setDateFieldsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = isMobileView();
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: null,
  });

  const { account, usage_details_status } = useSelector(state => state.user);
  const isFrench = locale === language.LANGUAGE_ISOCODE.French;

  useEffect(() => {
    if (state && state.startDate && state.endDate) {
      setDateFieldsError(false);
    }
  }, [state]);

  useEffect(() => {
    if (usage_details_status === "success") {
      setIsModalVisible(true);
    }
  }, [usage_details_status]);

  const onClose = () => {
    history.goBack();
  };

  const getUsageDetails = () => {
    if (!state.startDate || !state.endDate) {
      setDateFieldsError(true);
    } else {
      setIsModalVisible(true);
      let route = `/account/${accountCode}/usageDetails?`;

      const updatedFromDate = getISODateTimeStartOfDay(state.startDate);
      const updatedToDate = getISODateTimeEndOfDay(state.endDate);

      route += `from=${updatedFromDate}&to=${updatedToDate}`;

      dispatch(getUsage(route));
    }
  };

  const reset = () => {
    setDateFieldsError(false);
    setState({
      startDate: null,
      endDate: null,
      focusedInput: null,
    });
  };

  const closeDownload = () => {
    setIsModalVisible(false);
    dispatch(setUsageDetails(null));
  };

  if (!account || (account && account.accountCode !== accountCode)) {
    return <Layout />;
  }
  return (
    <>
      <Layout>
        <SubHeader />
        <Box>
          <Heading>
            {intl.formatMessage(
              { id: "lbl.usage_details" },
              {
                phoneNumber: isEmpty(account.phoneNumber) ? (
                  account.accountCode
                ) : (
                  <FormatPhone number={account.phoneNumber} />
                ),
                isMobile,
                br: <br />,
              }
            )}
          </Heading>

          <Flex
            px={["medium", "default", "large"]}
            py="larger"
            sx={{
              width: ["100%", "100%", "55.75rem", "55.75rem"],
              border: "1px solid",
              borderRadius: "card",
              borderColor: "border",
              flexDirection: "column",
              flex: "1",
            }}>
            <Text mb="small" sx={{ textAlign: ["center", "left", "left"] }}>
              <FormattedMessage id="lbl.usage_display.date_range" />
            </Text>

            <Datepicker
              minDate={getDateWithoutTimezone(account.registrationDate)}
              maxDate={new Date()}
              state={state}
              setState={setState}
            />

            {dateFieldsError && (
              <FormErrorMessage mt="0.4rem">
                <FormattedMessage id="err.usage_details.date_fields_mandatory" />
              </FormErrorMessage>
            )}

            <Flex
              mt="larger"
              sx={{
                justifyContent: ["space-between", "right"],
                flexDirection: isFrench ? ["column-reverse", "row"] : "row",
                alignItems: "center",
              }}>
              <Button
                sx={{ width: "10.5rem" }}
                variant="secondary"
                onClick={reset}
                mr={isFrench && isMobile ? 0 : "largeish"}
                mt={isFrench && isMobile ? "default" : 0}>
                <FormattedMessage id="lbl.reset" />
              </Button>

              <SubmitButton
                text="lbl.search"
                isLoading={usage_details_status === "loading"}
                onSubmit={getUsageDetails}
              />
            </Flex>
          </Flex>
          <GoBack onBack={onClose} />
        </Box>
      </Layout>

      <LoadDocumentModal
        isOpen={isModalVisible}
        onClose={closeDownload}
        loadingLabel="lbl.loading_usage_wait"
      />
    </>
  );
};

export default UsageDetails;
