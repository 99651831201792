import React from "react";
import { Flex, Heading, Text } from "@theme-ui/components";
import { FormattedMessage } from "react-intl";

import { useHistory } from "react-router-dom";
import { CBO_RESOLUTION_TYPE_CODE, STEP1 } from "../../common//Constants";
import { useSelector } from "react-redux";
import Update from "../Update/Update";
import { usePhoneNumber } from "../../hooks/usePhoneNumber";

const CBOCard = ({ cboTTicket, ...props }) => {
  const history = useHistory();

  const { account, master } = useSelector(state => state.user);
  const phoneNumber = usePhoneNumber(master?.udfCategory);
  const getDetailsByStatus = () => {
    if (cboTTicket.step === STEP1) {
      return "lbl.apply_cbo";
    }
    if (
      cboTTicket?.resolutionTypeCode &&
      cboTTicket?.status === "IN_PROGRESS" &&
      (cboTTicket?.resolutionTypeCode === CBO_RESOLUTION_TYPE_CODE.DSPRFI.CODE ||
        cboTTicket?.resolutionTypeCode === CBO_RESOLUTION_TYPE_CODE.DSPRCF.CODE)
    ) {
      return "lbl.reapply_cbo";
    }

    return "lbl.details_cbo";
  };

  const getMessageByStatus = () => {
    if (cboTTicket.step === STEP1) {
      return "lbl.cbo_eligible";
    }
    if (
      cboTTicket?.resolutionTypeCode &&
      cboTTicket?.resolutionTypeCode !== CBO_RESOLUTION_TYPE_CODE.APPR.CODE
    ) {
      return "lbl.cbo_reject";
    }
    if (cboTTicket?.resolutionTypeCode === CBO_RESOLUTION_TYPE_CODE.APPR.CODE) {
      return "lbl.cbo_approved";
    }
    if (cboTTicket.status === "IN_PROGRESS") {
      return "lbl.review_tt";
    }
  };

  return (
    <Flex sx={{ flexDirection: "column", ...props.sx }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.contract_buyout_header" />
      </Heading>
      <Flex
        variant="layout.card"
        sx={{
          height: ["5.75rem", "5.75rem", "5.75rem", "15.625rem"],
          flexDirection: ["row", "row", "row", "column"],
          justifyContent: ["space-between", "space-between", "space-between", "flex-start"],
        }}>
        <Text
          variant="note"
          color="textDark"
          sx={{
            alignSelf: "center",
            pr: ["0.1rem", "0.4rem"],
            width: ["70%", "70%", "72%", "70%"],
            display: ["block", "block", "block", "none"],
          }}>
          <FormattedMessage
            id={getMessageByStatus()}
            values={{ b: value => <b>{value}</b>, phoneNumber, category: master?.udfCategory }}
          />
        </Text>

        <Update
          pt={[0, 0, 0, "largeish"]}
          icon="notificationBell"
          text={getDetailsByStatus()}
          textProps={{
            sx: {
              whiteSpace: ["initial", "initial", "initial", "pre"],
              alignSelf: "center",
              maxWidth: "min-content",
              overflowWrap: "break-word",
              textWrap: "balance",
            },
          }}
          onUpdate={() => history.push("/account/" + account.accountCode + "/contractBuyout")}
        />

        <Flex sx={{ mt: "huge", display: ["none", "none", "none", "flex"] }}>
          <Text sx={{ pr: "0.4rem" }}>
            <FormattedMessage
              id={getMessageByStatus()}
              values={{ b: value => <b>{value}</b>, phoneNumber, category: master?.udfCategory }}
            />
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CBOCard;
