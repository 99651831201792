import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../rest/RestClient";
import { semaphore } from "../semaphore";
import { checkAndRefreshToken, REFRESH_DONE, REFRESH_FAIL } from "./AuthSlice";
import { UDF_CATEGORIES } from "../../common/Constants";

export const changeUsername = createAsyncThunk(
  "userSlice/changeUsername",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("userSlice/changeUsername"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.PUT(
        "/account/" + values.accountCode + "/changeUsername",
        {
          newUsername: values.newUsername,
        },
        { expectedResponse: "none" }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changePassword = createAsyncThunk(
  "userSlice/changePassword",
  async (values, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("userSlice/changePassword"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      await FacadeAPI.PUT(
        "/account/" + values.accountCode + "/changePassword",
        {
          password: values.password,
          newPassword: values.newPassword,
          newPasswordHint: values.newPasswordHint,
        },
        { expectedResponse: "none" }
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSecurityPolicy = createAsyncThunk(
  "userSlice/getSecurityPolicy",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("userSlice/getSecurityPolicy"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/security/securityPolicy");
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAccount = createAsyncThunk(
  "user/getAccount",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getAccount"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNearestBillableAccount = createAsyncThunk(
  "user/getNearestBillableAccount",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getNearestBillableAccount"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/nba");
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAccountList = createAsyncThunk(
  "user/getAccountList",
  async (args, { dispatch, rejectWithValue }) => {
    const { accountCode, chunkSize } = args;
    try {
      dispatch(checkAndRefreshToken("user/getAccountList"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/accountList?end=" + chunkSize
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getContacts = createAsyncThunk(
  "user/getContacts",
  async (accountCode, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getContacts"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const isMA = accountCode === getState().user.master?.accountCode;
      const response = await FacadeAPI.GET("/account/" + accountCode + "/contacts");

      return { response, isMA };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCountryStates = createAsyncThunk(
  "user/getCountryStates",
  async (countryCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getCountryStates"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/address/countries/" + countryCode + "/states");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const validateAddress = createAsyncThunk(
  "user/validateAddress",
  async ({ request }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/validateAddress"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      return await FacadeAPI.PUT(`/address/validateAddress`, request);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContacts = createAsyncThunk(
  "user/updateContacts",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/updateContacts"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const { accountCode, id, accountContact } = args;

      await FacadeAPI.PUT("/account/" + accountCode + "/contacts/" + id, accountContact, {
        expectedResponse: "none",
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAccountMessage = createAsyncThunk(
  "user/getAccountMessage",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getAccountMessage"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/accountMessage");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postAccountMessage = createAsyncThunk(
  "user/postAccountMessage",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/postAccountMessage"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.POST("/account/" + accountCode + "/dismissAccountMessage");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsageDetails = createAsyncThunk(
  "user/getUsageDetails",
  async (route, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getUsageDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(route, null, {
        noTimeout: true,
        expectedResponse: "blob",
      });

      return URL.createObjectURL(response);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeviceDetails = createAsyncThunk(
  "user/getDeviceDetails",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("user/getDeviceDetails"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET(
        "/account/" + accountCode + "/deviceInstallmentDetails",
        null,
        { noTimeout: true }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCboTicket = createAsyncThunk(
  "account/getCboTicket",
  async (accountCode, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("account/getCBOTicket"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.GET("/account/" + accountCode + "/getCBOTicket");

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const moveToVerifyProblem = createAsyncThunk(
  "account/moveToVerifyProblem",
  async ({ accountCode, processId, isFirstStep }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("account/getCBOTicket"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      const response = await FacadeAPI.PUT(
        "/account/" +
          accountCode +
          "/moveToVerifyProblem?processId=" +
          processId +
          "&isFirstStep=" +
          isFirstStep,
        null,
        { expectedResponse: "none" }
      );

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addDocument = createAsyncThunk(
  "account/addDocument",
  async ({ accountCode, processId, file }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(checkAndRefreshToken("account/addDocument"));
      await semaphore(REFRESH_DONE, REFRESH_FAIL);
      return await FacadeAPI.POST(
        "/account/" + accountCode + "/addDocument",
        { file, processId },
        {
          isFile: true,
          expectedResponse: "none",
        }
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

function initValidateAddress(state, action) {
  state.validateAddressInfo = {};
  state.validateAddressInfo.address = action.payload.address.street1;
  if (action.payload.address.street2 !== "") {
    state.validateAddressInfo.address += " " + action.payload.address.street2;
  }
  state.validateAddressInfo.city = action.payload.address.city;
  state.validateAddressInfo.postalCode = action.payload.address.postalCode;
  state.validateAddressInfo.province = action.payload.address.state;
  state.validateAddressInfo.response = action.payload.response;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userLoading: null,
    userError: null,

    master: null,
    account: null,
    loginDetails: null,
    serviceAgreements: null,
    additionalServ: null,
    usageDetails: null,
    contacts: null,
    maContacts: null,
    prevAccountCode: null,
    securityPolicy: null,
    totalNumAccounts: null,
    deviceDetails: null,
    ippay_params: null,
    provinces: null,
    validateAddressInfo: null,
    cboTTicket: null,

    isCommercialAccount: false,
    accountMessage: "account message test",
    navigationDisplay: "default",
    sharedMembersDisplay: "default",
    defaultNavigationPath: "/home",

    contacts_status: null,
    account_status: null,
    account_list_status: null,
    nba_account_status: null,
    cbo_tTicket_status: null,
    security_policy_status: null,
    change_username_status: null,
    change_password_status: null,
    update_contacts_status: null,
    load_provinces_status: null,
    validate_address_status: null,
    terms_conditions_status: null,
    account_message_status: null,
    usage_details_status: null,
    login_account_status: null,
    device_details_status: null,
    move_to_verify_problem_stuatus: null,
    add_document_status: null,
  },
  reducers: {
    setDeviceDetailsStatus: (state, action) => {
      state.device_details_status = action.payload;
    },
    setChangeUsernameStatus: (state, action) => {
      state.change_username_status = action.payload;
    },
    setUsername: (state, action) => {
      state.loginDetails.webLoginName = action.payload;
    },
    setChangePasswordStatus: (state, action) => {
      state.change_password_status = action.payload;
    },
    setSecurityPolicyStatus: (state, action) => {
      state.security_policy_status = action.payload;
    },
    setAccountList: (state, action) => {
      state.serviceAgreements = action.payload;
    },
    setMaster: (state, action) => {
      state.master = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setPrevAccountCode: (state, action) => {
      state.prevAccountCode = action.payload;
    },
    setSharedMembersDisplay: (state, action) => {
      state.sharedMembersDisplay = action.payload;
    },
    setAdditionalServ: (state, action) => {
      state.additionalServ = action.payload;
    },
    setBillingSummary: (state, action) => {
      state.billingSummary = action.payload;
    },
    setNbaAccountStatus: (state, action) => {
      state.nba_account_status = action.payload;
    },
    setAccountStatus: (state, action) => {
      state.account_status = action.payload;
    },
    setAccountListStatus: (state, action) => {
      state.account_list_status = action.payload;
    },
    setBillingSummaryStatus: (state, action) => {
      state.billing_summary_status = action.payload;
    },
    setSharedPlanStatus: (state, action) => {
      state.shared_plans_status = action.payload;
    },
    setContactsStatus: (state, action) => {
      state.contacts_status = action.payload;
    },
    setLoadProvincesStatus: (state, action) => {
      state.load_provinces_status = action.payload;
    },
    clearValidateAddressStatus: state => {
      state.validate_address_status = null;
    },
    clearUpdateContactsStatus: state => {
      state.update_contacts_status = null;
    },

    clearMoveToVerifyProblemStatus: state => {
      state.move_to_verify_problem_stuatus = null;
    },
    clearAddDocumentStatus: state => {
      state.add_document_status = null;
    },

    clearValidateAddressApiResponse: state => {
      state.validateAddressInfo = null;
      state.validate_address_status = null;
    },
    setTermsConditionsStatus: (state, action) => {
      state.terms_conditions_status = action.payload;
    },
    setUsageDetailsStatus: (state, action) => {
      state.usage_details_status = action.payload;
    },
    setUsageDetails: (state, action) => {
      state.usageDetails = action.payload;
    },
    setUserError: (state, action) => {
      state.userError = action.payload;
    },
    setIppayParams: (state, action) => {
      state.ippay_params = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
  },

  extraReducers: {
    [changeUsername.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.change_username_status = "loading";
    },
    [changeUsername.fulfilled]: state => {
      state.userLoading = false;
      state.change_username_status = "success";
    },
    [changeUsername.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.change_username_status = "failed";
    },
    [changePassword.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.change_password_status = "loading";
    },
    [changePassword.fulfilled]: state => {
      state.userLoading = false;
      state.change_password_status = "success";
    },
    [changePassword.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.change_password_status = "failed";
    },

    [getSecurityPolicy.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.security_policy_status = "loading";
    },
    [getSecurityPolicy.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.securityPolicy = action.payload;
      state.security_policy_status = "success";
    },
    [getSecurityPolicy.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.security_policy_status = "failed";
    },

    [getAccount.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.account_status = "loading";
    },
    [getAccount.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.account = action.payload;
      state.account_status = "success";
    },
    [getAccount.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.account_status = "failed";
    },

    [getNearestBillableAccount.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.nba_account_status = "loading";
    },
    [getNearestBillableAccount.fulfilled]: (state, action) => {
      const { master, account, loginDetails } = action.payload;
      state.userLoading = false;
      state.master = master;
      state.account = account;
      state.loginDetails = loginDetails;
      state.defaultNavigationPath =
        master.serviceAgreement || master.udfCategory !== UDF_CATEGORIES.COMMERCIAL
          ? "/home"
          : "/lines";
      state.displayName = account ? account.firstName : master.firstName;
      state.nba_account_status = "success";
    },
    [getNearestBillableAccount.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.nba_account_status = "failed";
    },

    [getAccountList.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.account_list_status = "loading";
    },
    [getAccountList.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.serviceAgreements = action.payload.accountList;
      state.totalNumAccounts = action.payload.totalNumberOfAccounts;
      state.account_list_status = "success";
    },
    [getAccountList.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.account_list_status = "failed";
    },

    [getContacts.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.contacts_status = "loading";
    },
    [getContacts.fulfilled]: (state, action) => {
      state.userLoading = false;
      if (action.payload.isMA) {
        state.maContacts = action.payload.response;
      } else {
        state.contacts = action.payload.response;
      }
      state.contacts_status = "success";
    },
    [getContacts.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.contacts_status = "failed";
    },

    [getCountryStates.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.load_provinces_status = "loading";
    },
    [getCountryStates.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.provinces = action.payload.countryStates;
      state.load_provinces_status = "success";
    },
    [getCountryStates.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.load_provinces_status = "failed";
    },

    [updateContacts.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.update_contacts_status = "loading";
    },
    [updateContacts.fulfilled]: state => {
      state.userLoading = false;
      state.update_contacts_status = "success";
    },
    [updateContacts.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.update_contacts_status = "failed";
    },

    [getAccountMessage.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.account_message_status = "loading";
    },
    [getAccountMessage.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.accountMessage = action.payload;
      state.account_message_status = "success";
    },
    [getAccountMessage.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.account_message_status = "failed";
    },

    [getUsageDetails.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.usage_details_status = "loading";
    },
    [getUsageDetails.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.usageDetails = action.payload;
      state.usage_details_status = "success";
    },
    [getUsageDetails.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.usage_details_status = "failed";
    },

    [getDeviceDetails.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.device_details_status = "loading";
    },
    [getDeviceDetails.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.deviceDetails = action.payload;
      state.device_details_status = "success";
    },
    [getDeviceDetails.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.device_details_status = "failed";
    },

    [validateAddress.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.validate_address_status = "loading";
    },
    [validateAddress.fulfilled]: (state, action) => {
      state.userLoading = false;
      initValidateAddress(state, action);
      state.validate_address_status = "success";
    },
    [validateAddress.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.validate_address_status = "failed";
    },
    [getCboTicket.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.cbo_tTicket_status = "loading";
    },
    [getCboTicket.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.cboTTicket = action.payload;
      state.cbo_tTicket_status = "success";
    },
    [getCboTicket.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.cbo_tTicket_status = "failed";
    },

    [addDocument.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.add_document_status = "loading";
    },
    [addDocument.fulfilled]: state => {
      state.userLoading = false;
      state.add_document_status = "success";
    },
    [addDocument.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.add_document_status = "failed";
    },
    [moveToVerifyProblem.pending]: state => {
      state.userLoading = true;
      state.userError = null;
      state.move_to_verify_problem_stuatus = "loading";
    },
    [moveToVerifyProblem.fulfilled]: state => {
      state.userLoading = false;
      state.move_to_verify_problem_stuatus = "success";
    },
    [moveToVerifyProblem.rejected]: (state, action) => {
      state.userLoading = false;
      state.userError = action.payload;
      state.move_to_verify_problem_stuatus = "failed";
    },
  },
});

export const {
  setUserError,
  setAccountList,
  setAccount,
  setSharedMembersDisplay,
  setAdditionalServ,
  setMaster,
  setContactsStatus,
  setLoadProvincesStatus,
  clearValidateAddressStatus,
  clearValidateAddressApiResponse,
  clearUpdateContactsStatus,
  clearAddDocumentStatus,
  clearMoveToVerifyProblemStatus,
  setNbaAccountStatus,
  setAccountStatus,
  setUsageDetailsStatus,
  setChangeUsernameStatus,
  setUsername,
  setChangePasswordStatus,
  setDeviceDetailsStatus,
  setUsageDetails,
  setIppayParams,
  setSecurityPolicyStatus,
  setPrevAccountCode,
  setDisplayName,
} = userSlice.actions;

export default userSlice.reducer;
